/*Шаблон заголовка страниц*/

@import "base-vars";

.page-header__title,
.page-title,
.page-subtitle {
  font-weight: 400;
  font-family: $font-serif;
  line-height: 1;
  text-transform: uppercase;
  color: $color-main;
}

.page-header {
  position: relative;
  display: flex;
  flex-direction: column;
  padding-top: 2rem;
  padding-bottom: 2rem;
  
  &__text {
    position: relative;
    z-index: 2;
    margin-top: auto;
  }
  
  &__description {
    margin-top: 1rem;
    max-width: 25rem;
    color: #d4d4d4;
    line-height: 1.2;
  }
  
  &__image {
    position: absolute;
    top: 0;
    left: 0;
    display: none;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-color: $color-black;
    
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: block;
      background: rgba(0, 0, 0, 0.25);
      background: linear-gradient(to bottom, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0.68) 100%);
    }
    
    &--no-dark::after {
      display: none;
    }
    
    &--grayscale {
      filter: grayscale(100%);
    }
  }
  
  &--large {
    height: 16.25rem;
    margin-bottom: 1.25rem;
    
    .page-header__image {
      display: block;
    }
  }
}

.page-title {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.page-subtitle {
  margin-bottom: 1rem;
}

@media (min-width: 1024px) {

  .page-header {
    height: 18.75rem;
    padding-top: 0;
    margin-bottom: 3.5rem;
    
    &__image {
      display: block;
    }
  }
  
  .page-header__nav {
    z-index: 2;
    margin-top: 2rem;
    margin-bottom: -2rem;
    background-color: rgba(153, 153, 153, 0.4);
    
    &-link {
      display: block;
      padding: 0.75rem 0;
      line-height: 1;
      border-top: 3px solid rgba(0, 0, 0, 0);
      
      &:hover {
        color: $color-main;
        
        .readmore-link {
          color: #f1eee9;
        }
      }
      
      &--current {
        color: $color-main;
        border-color: $color-main;
      }
    }
    
    &--small {
      padding-top: 0.75rem;
      padding-bottom: 0.75rem;
      
      .page-header__nav-link {
        padding: 0.5rem 0;
        border: 0;
      }
    }
    
    &--dark {
      background-color: rgba(51, 51, 51, 0.6);
    }
    
    &--fixed {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      z-index: 3;
      margin: 0;
    }
  }

}

@media (min-width: 1280px) {

  .page-header {
    margin-bottom: 4rem;
    background-color: $color-black;
    
    &::after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      display: block;
      width: 100%;
      height: 5rem;
      background-color: #fff;
    }
    
    &__nav {
      margin-left: auto;
      margin-right: auto;
    }
    
    &__image {
      left: 50%;
      width: 1220px;
      margin-left: -610px;
    }
  }

}