/*автокомплит для поиска*/

@import "base-vars";

.autocomplete-suggestions {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 1;
  max-height: 75vh;
  margin-top: 1px;
  padding: 0.5rem 0;
  font-size: 0.875em;
  background-color: #fff;
  box-shadow: 0 5px 10px rgba(0,0,0,0.15);
  overflow-y: auto;
  
  strong {
    font-weight: 400;
    color: $color-main;
  }
  
  .link {
    color: inherit;
  }
}

.autocomplete-suggestion {
  padding: 0.5rem 1rem;
  
  &--empty {
    color: $color-text-gray;
    text-align: center;
  }
}