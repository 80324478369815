/* Список брендов с разбивкой на алфавитные разделы */
@import "base-vars";

.page-brand__header {
  position: relative;
  margin: 1.5rem 0;
}

.page-brand__search {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 100%;
  
  &-control {
    bottom: -0.5rem;
    right: -0.5rem;
  }
  
  &-form {
    right: 0;
    bottom: -0.5rem;
    width: 0;
    background-color: #fff;
    border-bottom: 1px solid #d8d8d8;
    overflow: hidden;
    &.is-active {
      width: 100%;
    }
  }
  
  &-field {
    display: block;
    width: 100%;
    height: 2.5rem;
    padding: 0.65rem 1rem 0.35rem 3rem;
    line-height: 1.5rem;
    font-family: $font-serif;
    color: $color-text-gray;
    opacity: 0;
    transition: $base-transition;
    
    &::placeholder {
      color: $color-text-gray;
      opacity: 1;
    }
    .page-brand__search-form.is-active & {
      opacity: 1;
    }
  }
}

.page-brand__list {
  
  &-item {
    margin-top: 1.5rem;
  }
  
  &-title {
    padding-bottom: 0.5rem;
    margin-bottom: 0.5rem;
    font-size: 1.5em;
    line-height: 1;
    border-bottom: 1px solid $color-main;
  }
  
  &-link {
    display: table;
    padding: 0.25rem 0;
    &:hover {
      color: $color-main;
    }
  }
}

@media (min-width: 1024px) {
  
  .page-brand__header {
    margin: 2rem 0;
  }
  
  .page-brand__search {
    width: 24.5rem;
    
    &-form {
      bottom: 0;
      width: 100%;
    }
    
    &-field {
      opacity: 1;
    }
  }
  
  .page-brand__list {
    margin: -1.5rem -1rem 0;
    column-count: 4;
    column-gap: 2rem;
    
    &-item {
      display: inline-block;
      vertical-align: top;
      min-width: 13.5rem;
      padding: 0 1rem;
    }
  }
  
}

@media (min-width: 1280px) {
  
  .page-brand__list-item {
    min-width: 16.5rem;
  }
  
}