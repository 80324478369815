/*Хлебные крошки*/

@import "base-vars";

.path {
  margin-top: 2.5rem;
  font-size: 0.8125em;
  font-weight: 400;
  
  @media(min-width: 1024px) {
    margin-top: 3.5rem;
  }
  
  @media(min-width: 1280px) {
    margin-top: 4rem;
  }
  
  &__content {
    padding-top: 0.5rem;
    border-top: 1px solid #ccbca1;
  }
  
  .link {
    color: inherit;
  }
}

.path__item {
  position: relative;
  display: inline-block;
  vertical-align: top;
  margin-right: 2.25rem;
  
  &::before, &::after {
    content: '';
    position: absolute;
    left: 100%;
    top: 50%;
    display: block;
    width: 0;
    height: 0;
    margin-top: -3px;
    border: 4px solid rgba(0, 0, 0, 0);
  }
  
  &::before {
    margin-left: 16px;
    border-left: 5px solid #333;
  }
  
  &::after {
    margin-left: 15px;
    border-left: 5px solid #fff;
  }
  
  &:last-child {
    margin-right: 0;
    color: #999;
    
    &::before, &::after {
      display: none;
    }
  }
}

.path--inline {
  margin-top: 0;
  margin-bottom: 1.5rem;
  color: #797979;
  
  .path__content {
    padding: 0;
    border: 0;
  }
  
  .path__item:last-child {
    color: $color-black;
    font-weight: 700;
  }
}