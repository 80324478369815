/*Здесь указаны все переменные для сайта*/

$font-serif: 'Minion Pro', serif;
$font-sans-serif: 'Open Sans', sans-serif;

$color-main: #b1a082;
$color-main-dark: #9c9274;
$color-black: #333;

$color-text-gray: #666;
$color-text-warning: #bf5d5d;

$color-bg-gray: #ebebeb;

$base-transition: all 0.3s ease;