/*Типографика*/

@import "base-vars";

body {
  font: 300 1em/1.5 $font-sans-serif;
  color: $color-black;
}

b, strong {
  font-weight: 700;
}

.link {
  line-height: 1;
  color: #b1a082;
  border-bottom: 1px solid;
  cursor: pointer;
  transition: all 0.3s;
  
  &:hover {
    border-bottom-color: rgba(0,0,0,0);
  }
  
  &--inverse {
    border-bottom-color: rgba(0,0,0,0);
    &:hover {
      border-bottom-color: initial;
    } 
  }
  
  &--pseudo {
    border-bottom-style: dashed;
    &:hover {
      border-bottom-color: rgba(0,0,0,0);
    }
    &.link--inverse:hover {
      border-bottom-color: initial;
    }
  }
}

.readmore-link {
  position: relative;
  display: inline-block;
  vertical-align: top;
  padding-right: 3.25rem;
  font-family: 'Minion Pro', serif;
  color: #b1a082;
  transition: all 0.3s;
  
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    right: 0;
    display: block;
    width: 45px;
    height: 10px;
    margin-top: -1px;
    background: url(../images/readmore-arrow.svg) no-repeat right center;
    transform: translateY(-50%);
    transition: all 0.3s;
  }
  
  &:hover, &.is-active {
    color:#99835c;
    &::after {
      right: -10px;
    }
  }
  &--dark {
    color:#666;
    &::after {
      background-image: url(../images/readmore-arrow-gray.svg);
    }
  }

  &--rounded {
    min-width: 10rem;
    height: 2.5rem;
    padding: 0.5rem 1rem;
    line-height: 1.5rem;
    text-align: center;
    color: #333;
    background-color: rgba(0,0,0,0); 
    box-shadow: 0 0 0 1px #d0c6b4;
    &::after {
      right: -23px;
    }
    &:hover {
      color: #fff;
      background-color: #b1a082;
      &::after {
        right: -33px;
      }
    }
  }

  @media (min-width: 1280px) {
    font-size: 1.125rem;
  }
}

.title {
  font-weight: 400;
  font-family: 'Minion Pro', serif;
  line-height: 1;
  text-transform: uppercase;
  color: #b1a082;
  &--bordered {
    position: relative;
    &::before {
      content: '';
      position: absolute;
      top: 45%;
      left: 0;
      display: block;
      width: 100%;
      height: 1px; 
      background-color: #cdc4b5;
    }
    &  span {
      position: relative;
      display: table;
      margin: 0 auto;
      padding: 0 1.5rem;
      background-color: #fff;
    }
  }
}

.price {
  font: 600 1em/1 $font-serif;
  white-space: nowrap;
  color: $color-main;
  
  &__item {
    margin-left: 0.25em;
    
    &:first-child {
      margin-left: 0;
    }
    
    &--value {
      font-size: 1.375em;
    }
    
    &--old {
      color: #666;
      text-decoration: line-through;
    }
  }
  
  &--large .price__item {
    font-size: 1.125em;

    &--value {
      font-size: 1.5em;
      @media (min-width: 1280px) {
        font-size: 2em;
      }
    }
  }
}

h1, .text-1 {
  font-size: 1.5em;                 /*24px*/
  @media (min-width: 1024px) {
    font-size: 2em;                 /*32px*/
  }
}		
h2, .text-2 {font-size: 1.375em;}	/*22px*/
h3, .text-3 {font-size: 1.125em;}	/*18px*/
h4, .text-4 {font-size: 1em;}		/*16px*/
h5, .text-5 {font-size: 0.9375em;}	/*15px*/
h6, .text-6 {font-size: 0.875em;}	/*14px*/
	
.text-serif {
  font-family: 'Minion Pro', serif;
  font-weight: 400;
}

.text-italic {
  font-family: 'Minion Pro', serif;
  font-weight: 400;
  font-style: italic;
}

.text-left {text-align: left;}
.text-right {text-align: right;}
.text-center {text-align: center;}

.text-wrap {white-space: wrap;}
.text-nowrap {white-space: nowrap;}

.text-weight-light {font-weight: 300;} 
.text-weight-normal {font-weight: 400;}
.text-weight-medium {font-weight: 600;}
.text-weight-bold {font-weight: 700;}

.text-uppercase {text-transform: uppercase;}

.text-underline {text-decoration: underline;}

.color-black 	{color: #333;}
.color-white 	{color: #fff;}
.color-gray 	{color: #999;}
.color-darkgray {color: #666;}
.color-main		{color: #b1a082;}
.color-warning	{color: #bf5d5d;}

.user-content {
  font-size: 13px;
  line-height: 1.71;
  font-weight: 400;
  color: #666;
}

.user-content a {
  text-decoration: underline;
  color: #b1a082;
  &:hover {
    text-decoration: none;
  }
}

.user-content strong {
  color: #333;
  font-weight: 400;
}

.ul-list,
.ol-list,
.user-content ul,
.user-content ol {
  display: table;
}

.ul-list li,
.ol-list li,
.user-content li {
  position: relative;
  display: block;
  margin-bottom: 0.5em;
  padding-left: 2em;
}

.ul-list li:last-child,
.ol-list li:last-child,
.user-content li:last-child {
  margin-bottom: 0;
}

.ul-list li li,
.ol-list li li,
.user-content li li{
  margin-top: 0.25em;
  margin-bottom: 0;
}

.ul-list li::before,
.user-content ul > li::before {
  content: '';
  position: absolute;
  top: 0.75em;
  left: 0;
  width: 0.75em;
  height: 1px;
  background-color: #b1a082;
}

.ul-list li li::before,
.user-content ul > li > ul > li::before {
  top: 0.5em;
  width: 0.5em;
  height: 0.5em;
  border-radius: 50%;
}

.ol-list,
.user-content ol {
  counter-reset: list1;
}

.user-content ol > li > ol {
  counter-reset: list2;
}

.ol-list li::before,
.user-content ol > li::before {
  content: counter(list1)'.';
  counter-increment: list1;
  position: absolute;
  top:0;
  left: 0;
  margin-right: 0.75em;
  color: #b1a082;
  font-weight: 400;
}

.user-content ol > li > ol > li::before {
  content: counter(list1)'.'counter(list2)'.';
  counter-increment: list2;
  color: #333;
}

.table,
.user-content table {
  width: 100%;
  border: 1px solid #e1e1e1;
  background-color: #fafafa;
}

.table td,
.table th,
.user-content td,
.user-content th {
  vertical-align: top;
  padding: 0.75rem 1rem;
  border: 1px solid #e1e1e1;
  
  @media (min-width: 1024px) {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  
  @media (min-width: 1280px) {
    padding-left: 2rem;
    padding-right: 2rem;
  }
  
}

.table th,
.user-content th {
  font-size: 0.9375em;
  font-weight: 400;
  text-align: left;
  color: #999;
}

.table td,
.user-content td {
  font-weight: 400;
  font-family: 'Minion Pro', serif;
}

.user-content h1, 
.user-content h2, 
.user-content h3, 
.user-content h4,
.user-content h5 {
  margin: 1em 0 0.5em;
  font-weight: 400 !important;
  line-height: 1.2;
  letter-spacing: 0.02em;
  color: #333;
}

.user-content h1, 
.user-content h2 {
  font-family: 'Minion Pro', serif;
  text-transform: uppercase;
  color: #b1a082;
}

.user-content p,
.user-content ul,
.user-content ol,
.user-content table {
  margin-bottom: 1em;
}

.user-content h1:first-child,
.user-content h2:first-child,
.user-content h3:first-child,
.user-content h4:first-child,
.user-content h5:first-child {
  margin-top: 0;
}

.user-content h1:last-child,
.user-content h2:last-child,
.user-content h3:last-child,
.user-content h4:last-child,
.user-content h5:last-child,
.user-content p:last-child,
.user-content ul:last-child,
.user-content ol:last-child,
.user-content table:last-child {
  margin-bottom: 0;
}

.user-content img {
  vertical-align: top;
  height: auto !important;
  width: auto !important;
}