/*Превью товара*/

@import "base-vars";

.product-list.is-loading {
  opacity: 0.5;
}

.product-list__name {
  padding: 0.75rem 0;
  font-size: 0.9375em;
  line-height: 1.2;
  
  @media (min-width: 1280px) {
    font-size: 1.125em;
  }
}

.product-list--static .product-list__item {
  margin-bottom: 1rem;
  padding-bottom: 1.5rem;
  border-bottom: 1px solid #f0f0f0;
  
  &:last-child {
    @media (max-width: 767px) {
      margin-bottom: 0;
      padding-bottom: 0;
      border: 0;
    }
  }
  
  @media (min-width: 768px) and (max-width: 1023px) {
    width: 50%;
  }
}

@media (min-width: 768px) {
  
  .product-list--static {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: -3.5rem;
    
    .product-list__item {
      float: none;
      margin-bottom: 2rem;
    }
    
    &::after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: 2rem;
      display: block;
      height: 1px;
      background-color: #fff;
    }
  }
}

@media (min-width: 1024px) {
  
  .product-list__image {
    position: relative;
    overflow: hidden;
    
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
      display: block;
      width: 100%;
      height: 100%;
      background-color: rgba(0,0,0,0.7);
      opacity: 0;
      transition: $base-transition;
    }
  }
  
  .product-list__readmore {
    position: absolute;  
    top: -100%;
    left: 50%;
    z-index: 3;
    transform: translate3d(-50%,-50%,0);
  }
  
  .product-list__card:hover {
    
    .product-list__readmore {
      top: 50%;
    }
    
    .product-list__image::before {
      opacity: 1;
    }
  }
}