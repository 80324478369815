@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,400i,600,700');

@font-face {
  font-family: Calibri;
  src: local('Calibri-Light'),
       local('Calibri Light'),
       url(../fonts/calibri-light.ttf);
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: Calibri;
  src: local('Calibri'),
       local('Calibri Regular'),
       url(../fonts/calibri-regular.ttf);
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: Calibri;
  src: local('Calibri-Bold'),
       local('Calibri Bold'),
       url(../fonts/calibri-bold.ttf);
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: 'Minion Pro';
  src: local('MinionPro-Regular'),
       url(../fonts/minion-pro-regular.otf);
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: 'Minion Pro';
  src: local('MinionPro-Medium'),
       url(../fonts/minion-pro-medium.otf);
  font-style: normal;
  font-weight: 600;
}

@font-face {
  font-family: 'Minion Pro';
  src: local('MinionPro-It'),
       url(../fonts/minion-pro-italic.otf);
  font-style: italic;
  font-weight: 400;
}