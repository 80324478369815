/*Модальная галерея*/

@import "base-vars";

.modal-gallery-open {
  overflow: hidden;
}

.modal-gallery {
  position: fixed;
  top: 100%;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 20;
  background: #333 url(../images/pattern.jpg) top left;
  overflow: hidden;
  transition: all 0.3s ease;
}

.modal-gallery.is-opened {
  top: 0;
  padding-top: 7rem;
  padding-bottom: 5rem;
}

.modal-gallery__header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 5rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  background-color: #333;
  border-bottom: 1px solid #484848;
  opacity: 0;
  transition: all 0.3s;
}

.modal-gallery.is-ready .modal-gallery__header {
  opacity: 1;
  transition-delay: 0.3s;
}

.modal-gallery__body {
  opacity: 0;
  transition: opacity 0.3s ease;
}

.modal-gallery.is-ready .modal-gallery__body {
  opacity: 1;
  transition-delay: 0.6s;
}

.modal-gallery__footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 3.5rem;
  background-color: #333;
  border-top: 1px solid #484848;
  opacity: 0;
  transition: all 0.3s;
}

.modal-gallery.is-ready .modal-gallery__footer {
  opacity: 1;
  transition-delay: 0.3s;
}

.modal-gallery__title {
  margin-top: 0.25rem;
  padding: 0 2rem;
  text-align: center;
}

.modal-gallery__close {
  position: absolute;
  top: 50%;
  right: 2rem;
  color: #666;
  margin-top: -1.25rem;
}

.modal-gallery__body {
  height: 100%;
}

.modal-gallery__slide {
  margin: 0 0.5rem;
  max-width: 95vw;
  height: calc(100vh - 12rem);
  background-color: #141414;
}

.modal-gallery__slide img {
  height: 100%;
  opacity: 0.3;
  transition: opacity 0.9s cubic-bezier(0.45, 0.1, 0.45, 0.9);
}

.modal-gallery__slide.slick-current img {
  opacity: 1;
}

.modal-gallery__control {
  text-align: center;
}

.modal-gallery__control-count {
  display: inline-block;
  vertical-align: middle;
  padding: 0 1rem;
  color: #7b7979;
  font-size: 13px;
  line-height: 1;
}

.modal-gallery__control-count .current-slide {
  font-size: 24px;
  font-weight: 400;
  color: #b1a082;
}

.modal-gallery__control .slick-arrow {
  position: static;
  display: inline-block;
  vertical-align: middle;
  white-space: nowrap;
  padding: 0 1rem;
  width: auto;
  height: auto;
  margin: 0;
  color: #999;
  font-family: 'Minion Pro', serif;
}

.modal-gallery__control .slick-arrow:hover {
  color: #fff;
}

.modal-gallery__control .slick-arrow .icon-text {
  margin-top: 0.25rem;
}

.modal-gallery .image-frame {
  padding: 0;
  height: 100%;
}

.modal-gallery .image-frame img {
  width: 100%;
}
