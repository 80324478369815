html {font-size: 16px;}

body {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.container {
  position: relative;
  display: block;
  width: 100%;
  max-width: 100%;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-left: auto;
  margin-right: auto;
}

.row {
  margin-left: -1rem;
  margin-right: -1rem;
}

.row--small {
  margin-left: -0.5rem;
  margin-right: -0.5rem;
}

.col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12, .col-xs-fluid,
.col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-fluid,
.col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md-fluid,
.col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-fluid{
  position: relative;
  display: block;
  min-height: 1px;
  padding-left: 1rem;
  padding-right: 1rem;
}

.col--small {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.col-xs-fluid, .col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, 
.col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12 {float: left;}

.col-xs-fluid {width: auto;}

.col-xs-12	{width: 100%;}
.col-xs-11	{width: 91.66666667%;}
.col-xs-10	{width: 83.33333333%;}
.col-xs-9	{width: 75%;}
.col-xs-8	{width: 66.66666667%;}
.col-xs-7	{width: 58.33333333%;}
.col-xs-6	{width: 50%;}
.col-xs-5	{width: 41.66666667%;}
.col-xs-4	{width: 33.33333333%;}
.col-xs-3	{width: 25%;}
.col-xs-2	{width: 16.66666667%;}
.col-xs-1	{width: 8.33333333%;}

.offset-xs-12	{margin-left: 100%;}
.offset-xs-11	{margin-left: 91.66666667%;}
.offset-xs-10	{margin-left: 83.33333333%;}
.offset-xs-9	{margin-left: 75%;}
.offset-xs-8	{margin-left: 66.66666667%;}
.offset-xs-7	{margin-left: 58.33333333%;}
.offset-xs-6	{margin-left: 50%;}
.offset-xs-5	{margin-left: 41.66666667%;}
.offset-xs-4	{margin-left: 33.33333333%;}
.offset-xs-3	{margin-left: 25%;}
.offset-xs-2	{margin-left: 16.66666667%;}
.offset-xs-1	{margin-left: 8.33333333%;}
.offset-xs-0	{margin-left: 0;}

@media (min-width: 1024px) {

  .container {width: 960px;}
  
  .col-md-fluid, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, 
  .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12 {float: left;}

  .col-md-fluid {width: auto;}
  
  .col-md-12	{width: 100%;}
  .col-md-11	{width: 91.66666667%;}
  .col-md-10	{width: 83.33333333%;}
  .col-md-9	{width: 75%;}
  .col-md-8	{width: 66.66666667%;}
  .col-md-7	{width: 58.33333333%;}
  .col-md-6	{width: 50%;}
  .col-md-5	{width: 41.66666667%;}
  .col-md-4	{width: 33.33333333%;}
  .col-md-3	{width: 25%;}
  .col-md-2	{width: 16.66666667%;}
  .col-md-1	{width: 8.33333333%;}
  
  .offset-md-12	{margin-left: 100%;}
  .offset-md-11	{margin-left: 91.66666667%;}
  .offset-md-10	{margin-left: 83.33333333%;}
  .offset-md-9	{margin-left: 75%;}
  .offset-md-8	{margin-left: 66.66666667%;}
  .offset-md-7	{margin-left: 58.33333333%;}
  .offset-md-6	{margin-left: 50%;}
  .offset-md-5	{margin-left: 41.66666667%;}
  .offset-md-4	{margin-left: 33.33333333%;}
  .offset-md-3	{margin-left: 25%;}
  .offset-md-2	{margin-left: 16.66666667%;}
  .offset-md-1	{margin-left: 8.33333333%;}
  .offset-md-0	{margin-left: 0;}
  
}

@media (min-width: 1024px) and (max-width: 1279px) {
  
  .container {
    padding-left: 10px;
  	padding-right: 10px;
  }
  
  .row {
    margin-left: -10px;
    margin-right: -10px;
  }
  
  .col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12, .col-xs-fluid,
  .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-fluid,
  .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md-fluid,
  .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-fluid {
    padding-left: 10px;
    padding-right: 10px;
  }
  
}

@media (min-width: 1280px) {

  .container {
  	width: 1220px;
  	padding-left: 2.5rem;
  	padding-right: 2.5rem;
  }
  
  .col-lg-fluid, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, 
  .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12 {float: left;}
  
  .col-lg-fluid {width: auto;}
  
  .col-lg-12	{width: 100%;}
  .col-lg-11	{width: 91.66666667%;}
  .col-lg-10	{width: 83.33333333%;}
  .col-lg-9	{width: 75%;}
  .col-lg-8	{width: 66.66666667%;}
  .col-lg-7	{width: 58.33333333%;}
  .col-lg-6	{width: 50%;}
  .col-lg-5	{width: 41.66666667%;}
  .col-lg-4	{width: 33.33333333%;}
  .col-lg-3	{width: 25%;}
  .col-lg-2	{width: 16.66666667%;}
  .col-lg-1	{width: 8.33333333%;}
  
  .offset-lg-12	{margin-left: 100%;}
  .offset-lg-11	{margin-left: 91.66666667%;}
  .offset-lg-10	{margin-left: 83.33333333%;}
  .offset-lg-9	{margin-left: 75%;}
  .offset-lg-8	{margin-left: 66.66666667%;}
  .offset-lg-7	{margin-left: 58.33333333%;}
  .offset-lg-6	{margin-left: 50%;}
  .offset-lg-5	{margin-left: 41.66666667%;}
  .offset-lg-4	{margin-left: 33.33333333%;}
  .offset-lg-3	{margin-left: 25%;}
  .offset-lg-2	{margin-left: 16.66666667%;}
  .offset-lg-1	{margin-left: 8.33333333%;}
  .offset-lg-0	{margin-left: 0;}
	
}

.col--right {float: right;}

.row::after,
.row::before,
.clear::after,
.clear::before,
.container::after,
.container::before {
  content: '';
  display: table;
}

.row::after,
.clear::after,
.container::after{clear: both;}

.hidden {display: none !important;}
.visible {display: block !important;}

.visible-xs,
.visible-xs-flex,
.visible-xs-table,
.visible-xs-inline,
.visible-xs-inline-block,
.visible-md,
.visible-md-flex,
.visible-md-table,
.visible-md-inline,
.visible-md-inline-block,
.visible-lg,
.visible-lg-flex,
.visible-lg-table,
.visible-lg-inline,
.visible-lg-inline-block {display: none !important;}


@media (max-width: 1023px) {
  .visible-xs {display: block !important;}
  .visible-xs-table {display: table !important;}
  .visible-xs-inline {display: inline !important;}
  .visible-xs-inline-block {display: inline-block !important;}
  .visible-xs-flex {display: flex !important;}
  table.visible-xs {display: table !important;}
  tr.visible-xs {display: table-row !important;}
  th.visible-xs, 
  td.visible-xs {display: table-cell !important;}
}


@media (min-width: 1024px) and (max-width: 1279px) {
  .visible-md {display: block !important;}
  .visible-md-table {display: table !important;}
  .visible-md-inline {display: inline !important;}
  .visible-md-inline-block {display: inline-block !important;}
  .visible-md-flex {display: flex !important;}
  table.visible-md {display: table !important;}
  tr.visible-md {display: table-row !important;}
  th.visible-md, 
  td.visible-md {display: table-cell !important;}
}

@media (min-width: 1280px) {
  .visible-lg {display: block !important;}
  .visible-lg-table {display: table !important;}
  .visible-lg-inline {display: inline !important;}
  .visible-lg-inline-block {display: inline-block !important;}
  .visible-lg-flex {display: flex;}
  table.visible-lg {display: table !important;}
  tr.visible-lg {display: table-row !important;}
  th.visible-lg, 
  td.visible-lg {display: table-cell !important;}
}

@media (max-width: 1023px)							{.hidden-xs {display: none !important;}}
@media (min-width: 1024px) and (max-width: 1279px)	{.hidden-md {display: none !important;}}
@media (min-width: 1280px)							{.hidden-lg {display: none !important;}}
