@import "base-vars";

/* Обратная совместимость и костыли помечены комментом FALLBACK */

/*
* КНОПКИ
* ФОРМЫ
* УВЕДОМЛЕНИЯ
* ИКОНКИ
* ТАБЫ
* ВЫБОР КОЛИЧЕСТВА
* ПАГИНАЦИЯ
* ПОДЕЛИТЬСЯ
* РАМКИ ДЛЯ ФОТО
* СПИСОК ПЛИТКОЙ
* МОДАЛЬНАЯ ГАЛЕРЕЯ
* СТРАНИЦА ОШИБОК
* МОДАЛЬНЫЕ БЛОКИ
* ТАБЛИЦА БРЕНДОВ
* КНОПКА ВВЕРХ
*/


/* BEGIN - КНОПКИ */



/* END - КНОПКИ */


/* BEGIN - ФОРМЫ */

.form {display: block;}

.form__header {margin-bottom: 2.5rem;}

.form__header-notice {margin-top: 0.5rem;}

.form__group {
	position: relative;
	margin-bottom: 1.75rem;
	transition: all 0.3s;
}

.form__group:last-child {margin-bottom: 0;}

.form__group-input,
.checkout_form input[type="text"] {
	display: block;
	width: 100%;
	height: 2.5rem;
	padding: 0.5rem 1.5rem;
	font: 400 1rem/1.5 'Minion Pro', serif;
	color: #333;
	border-bottom: 1px solid #d8ccb6;
	transition: all 0.3s;
	-webkit-border-radius: 0 !important; /*Костыль для старых сафари*/
}

textarea.form__group-input {
	min-width: 100%;
	height: 2.5rem;
	min-height: 2.5rem;
	overflow: hidden;
	white-space: nowrap;
    appearance: none;
}

.form__group-input:hover,
.form__group-input:focus,
.checkout_form input[type="text"]:hover,
.checkout_form input[type="text"]:focus {border-color: #b1a082;}

.form__group-input--error {border-color: #bf5d5d;}

.form__group-label {
	position: absolute;
	bottom: 0;
	left: 0;
	display: block;
	padding: 11px 1.5rem;
	font: 400 0.875rem/1 'Minion Pro', serif;
	font-style: italic;
	cursor: text;
	color: #a2a2a2;
    transition: all 0.3s;
}

.form__group-label--small,
.form__group-input:focus + .form__group-label {
	bottom: 100%;
	margin-bottom: -5px;
	padding: 0 1.5rem;
	font-size: 0.75rem;
}

.form__group-label--required::before {
	content: '\002A';
	position: absolute;
	top: 50%;
	left: 0;
	display: block;
	width: 1.5rem;
	height: 0.5rem;
	color: #b1a082;
	text-align: center;
	transform: translateY(-50%);
}

.form__group-error {
	position: absolute;
	top: 100%;
	left: 0;
	display: block;
	padding: 0 1.5rem;
	margin-top: 0.5rem;
	font-size: 0.75rem;
	line-height: 1;
	color: #bf5d5d;
	cursor: pointer;
}

.checkbox-label {
	position: relative;
	display: block;
	min-height: 1.25rem;
	padding-left: 2.25rem;
	line-height: 1.25rem;
	cursor: pointer;
}

.checkbox-label::before {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	display: block;
	width: 1.25rem;
	height: 1.25rem;
	background-color: #fff;
	background-position: center;
	background-repeat: no-repeat;
	border: 1px solid #ccc;
	border-radius: 2px;
    transition: all 0.3s;
}

.checkbox-label:hover::before {border-color: #b1a082;}

.checkbox-label.is-checked::before,
input.hidden:checked +.checkbox-label::before {
	border-color: #b1a082;
	background-color: #b1a082;
	background-image: url(../images/checkbox.svg);
}

.checkbox-label--radio::before {border-radius: 50%;}

.checkbox-label--radio.is-checked::before,
input.hidden:checked +.checkbox-label--radio::before {background: none;}

.checkbox-label--radio::after {
	content: '';
	position: absolute;
	top: 0.25rem;
	left: 0.25rem;
	display: block;
	width: 0.75rem;
	height: 0.75rem;
	border-radius: 50%;
	transition: all 0.3s;
}

.checkbox-label--radio.is-checked::after,
input.hidden:checked +.checkbox-label--radio::after {background-color: #99835c;}

select {
	display: block;
	width: 100%;
	min-width: 10.5rem;
	height: 2.5rem;
	padding: 0.75rem 40px 0.75rem 1.25rem;
	color: #333;
	font: 400 15px/1rem 'Minion Pro', serif;
	background: #fff url(../images/select-arrow.svg) right center no-repeat;
	border: 1px solid #e5e5e5;
	transition: all 0.3s;
	appearance: none;
}

select::-ms-expand {display: none;}

select:hover {border-color: #dac4ae;}

.select-label {color: #999;}

.upload-file {
    margin-top: -0.75rem;
    font-family: 'Minion Pro', serif;
}

.upload-file__list {
    display: none;
    margin-top: 1rem;
    padding: 1rem;
    background-color: #f3f0ea;
    border: 1px dashed #cbbca2;
}

.upload-file__item {
    display: none;
	margin-bottom: 1rem;
	white-space: nowrap;
}

.upload-file__item:last-child {margin-bottom: 0;}

.upload-file__add {
    display: table;
    color: #b1a082;
    cursor: pointer;
    white-space: nowrap;
}

.upload-file__add-icon {
    display: inline-block;
    vertical-align: top;
    width: 1.5rem;
    height: 1.5rem;
    margin-left: -3px;
}

.upload-file__add-text {
    display: inline-block;
    vertical-align: top;
    margin-left: 0.5rem;
    margin-top: 2px;
    border-bottom: 1px dotted;
    transition: all 0.3s ease;
}

.upload-file__add:hover .upload-file__add-text {border-color: rgba(0,0,0,0);}

.upload-file__label {
    display: inline-block;
    vertical-align: middle;
    max-width: 85%;
	color: #666;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.upload-file__name {
    font-size: 0.875em;
    color: #666666;
}

.upload-file__remove {
    display: inline-block;
    vertical-align: middle;
    width: 1.75rem;
    height: 1.75rem;
	padding: 0.25rem;
    margin-left: 1rem;
	cursor: pointer;
	color: #bf5d5d;
}

.upload-file__input {display: none;}

.form__content--sended {position: relative;}

.form__content--sended::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    display: block;
    background-color: rgba(255,255,255,0.85);
}

.form__content-notice {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    z-index: 2;
    padding-top: 5rem;
    line-height: 1.2;
    text-align: center;
    letter-spacing: 0.02em;
    color: #999;
    background: url(../images/icon-success.svg) no-repeat center top;
    background-size: 4rem;
    transform: translateY(-50%);
}

.form__content-notice h3 {
    margin-bottom: 0.5em;
    font-weight: 400;
    text-transform: uppercase;
    color: #666;
}

/* END - ФОРМЫ */


/* BEGIN - УВЕДОМЛЕНИЯ */

.notice {
	margin-bottom: 1em;
	padding: 0.5em 1em;
	border-left: 2px solid #b1a082;
}

/* END - УВЕДОМЛЕНИЯ */


/* BEGIN - ИКОНКИ */

.icon,
.icon-text {
	line-height: 1;
	display: inline-block;
	vertical-align: middle;
	white-space: normal;
}

.icon + .icon-text,
.icon-text + .icon {margin-left: 0.5em;}

.icon-container {
	display: flex;
	align-items: center;
}

.icon {
	width: 1.5rem;
    min-width: 1.5rem;
	height: 1.5rem;
}

.icon--readmore {
	width: 45px;
    min-width: 45px;
	height: 10px;
}

.icon--button {
	width: 2.5rem;
    min-width: 2.5rem;
	height: 2.5rem;
	padding: 0.5rem;
	cursor: pointer;
}

.icon svg {
    width: 100%;
    height: 100%;
}

.icon__menu {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
}

.icon__menu-line {
    position: absolute;
    left: 12.5%;
    display: block;
    width: 75%;
    height: 2px;
    background-color: #b09f81;
    transition: all 0.3s ease;
}

.icon__menu-line--first  {top: 25%;}
.icon__menu-line--second {top: 50%; margin-top: -1px;}
.icon__menu-line--third  {top: 66.666%;}

.is-active .icon__menu-line--first {
    top: 50%;
    margin-top: -1px;
    transform: rotate(45deg);
}

.is-active .icon__menu-line--second {width: 0;}

.is-active .icon__menu-line--third {
    top: 50%;
    margin-top: -1px;
    transform: rotate(-45deg);
}

/* END - ИКОНКИ */


/* BEGIN - ТАБЫ */

.tabs {margin: 0 -1rem;}

.tabs__header {margin-bottom: 2rem;}

.tabs__header-list {
    min-width: 100%;
    padding: 0 0.75rem;
    overflow-x: auto;
    white-space: nowrap;
}

.tabs__header-item {
    display: inline-block;
    vertical-align: middle;
    margin: 0 0.25rem;
    font: 400 0.9375em/1 'Minion Pro', serif;
}

.tabs__header-link {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 85vw;
    min-height: 4rem;
    padding: 1rem;
    text-align: center;
    white-space: normal;
    color: #fff;
    background-color: #b1a082;
}

.tabs__header-link.is-active {box-shadow: inset 0 -4px #7f7462;}

.tabs__item {
    padding: 0 1rem;
	max-height: 0;
	opacity: 0;
	visibility: hidden;
	overflow: hidden;
	transition: opacity 0.3s ease;
}

.tabs__item.is-opened {
	max-height: none;
	opacity: 1;
	visibility: visible;
}


/* END - ТАБЫ */


/* BEGIN - ВЫБОР КОЛИЧЕСТВА */

.amount {
	display: inline-block;
	vertical-align: middle;
	white-space: nowrap;
}

.amount__label {
	display: block;
    font-size: 0.875em;
	text-transform: uppercase;
}

.amount__input {
	display: inline-block;
	vertical-align: middle;
	width: 3rem;
	height: 2.5rem;
	padding: 0.25rem 0;
	font-size: 1.375em;
	font-weight: 400;
	line-height: 2rem;
	text-align: center;
	color: #b1a082;
	background-color: #fff;
	transition: all 0.3s;
	user-select: none;
}

.amount__trigger {
	position: relative;
	display: inline-block;
	vertical-align: middle;
	height: 2.5rem;
	padding: 0.5rem 0;
	width: 1.5rem;
	cursor: pointer;
	color: #666;
	user-select: none;
}

.amount__trigger:hover{color: #333;}

/* END - ВЫБОР КОЛИЧЕСТВА */


/* BEGIN - ПАГИНАЦИЯ */

.pagination {margin-top: 2.5rem;}

.pagination__control {padding: 0.5rem;}

.pagination__control .icon-text {
	margin-top: 2px;
	font-family: 'Minion Pro', serif;
}

.pagination__control--disabled {
	cursor: default;
	color: #ccc;
}

.pagination__count {
	margin: -2px 1.75rem 0;
	font-size: 13px;
	color: #7b7979;
}

.pagination__count-current {
	font-size: 22px;
	color: #b1a082;
}


/* END - ПАГИНАЦИЯ */


/* END - ПОДЕЛИТЬСЯ */

.share {
	margin-top: 1.5rem;
	padding-top: 1.5rem;
	border-top: 1px solid #f0f0f0;
}

.share--mini {
    margin: 0;
    padding: 0;
    border: 0;
}

.share__title {
	display: inline-block;
	vertical-align: middle;
	line-height: 1;
	color: #999;
}

.share__list {
	display: inline-block;
	vertical-align: middle;
	margin-left: 1rem;
}

.share__list-item  {
	display: inline-block;
	vertical-align: middle;
	margin-right: 0.5rem;
    white-space: nowrap;
	color: #666;
}

.share__list-item--houzz {height: 20px;}

.share__list-item:last-child {margin-right: 0;}

.social-likes__button {
	display: inline-block;
	vertical-align: top;
	width: 2rem;
	height: 2rem;
	padding: 0.25rem;
	border-radius: 50%;
	cursor: pointer;	
	transition: all 0.3s;
}

.social-likes__button:hover {color: #b1a082;}

.social-likes__counter {
	display: inline-block;
	vertical-align: top;
	height: 2rem;
	margin-left: 0.25rem;
	font-size: 0.875em;
	line-height: 2rem;
	color: #999;
}

.social-likes__counter_empty {display: none;}

/* END - ПОДЕЛИТЬСЯ */


/* BEGIN - РАМКИ ДЛЯ ФОТО */

.image-frame {
	position: relative;
	display: block;
	width: 100%;
	padding-bottom: 75%;
	overflow: hidden;
}

.image-frame--1to1 {padding-bottom: 100%;}
.image-frame--3to1 {padding-bottom: 33.333%;}
.image-frame--3to2 {padding-bottom: 66.666%;}
.image-frame--3to4 {padding-bottom: 133.3333%;}
.image-frame--fluid {
    height: 100%;
    padding-bottom: 0;
}


.image-frame img {
	position: absolute;
	top: 50%;
	left: 50%;
	min-height: 100%;
	min-width: 100%;
	max-height: 200%;
	
	-webkit-transform: translate3d(-50%,-50%,0);
			transform: translate3d(-50%,-50%,0);
}

@supports (object-fit: cover) {
	.image-frame img {
		width: 100%;
		height: 100%;
		left: 0;
		top: 0;
		min-height: 0;
		min-width: 0;
		max-height: none;
		object-fit: cover;
        transform: none;
	}
}

@supports (object-fit: contain) {
	.image-frame--contain img {
		position: absolute;
		top: 50%;
		left: 50%;
		width: auto;
		height: auto;
		object-fit: contain;
        transform: translate3d(-50%,-50%,0);
	}
}

/* END - РАМКИ ДЛЯ ФОТО */


/* BEGIN - СПИСОК ПЛИТКОЙ */

.grid-list {
	margin-bottom: -2.5rem;
	margin-left: -1rem;
	margin-right: -1rem;
}

.grid-list__item {
	float: none;
	margin-bottom: 2.5rem;
}

/* END - СПИСОК ПЛИТКОЙ */


/* BEGIN - МОДАЛЬНЫЕ БЛОКИ */

.modal-window-open,
.modal-notice-open {overflow: hidden;}

.modal-window {
	position: fixed;
    top: 100%;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 25;
	overflow: hidden;
	background-color: #fff;
    transition: all 0.3s;
}

.modal-window.is-active {
	top: 3.25rem;
	padding-top: 3.25rem;
}

.modal-window--has-footer.is-active {padding-bottom: 3.5rem;}

.modal-window__header {
	position: absolute;
	top: 0;
	left: 0;
    right: 0;
	z-index: 1;
	padding: 1rem 0;
	font-size: 1.25em;
	font-family: 'Minion Pro', serif;
	line-height: 1;
	text-transform: uppercase;
	color: #b1a082;
	background-color: #f7f7f7;
	border-bottom: 1px solid #e7e7e7;
	transition: all 0.3s;
}

.modal-window.is-active .modal-window__header {top: 0;}

.modal-window__close {
	position: absolute;
	top: 50%;
	right: 0;
	color: #999;
    transform: translateY(-50%);
	transition: all 0.3s;
}

.modal-window__close:hover {color: #bf5d5d;}

.modal-window__close .icon {padding: 0.75rem;}

.modal-window__footer {
	position: absolute;
	left: 0;
    right: 0;
	bottom: 0;
	z-index: 2;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
	background-color: #f7f7f7;
	border-top: 1px solid #e7e7e7;
    transition: all 0.3s;
}

.modal-window__footer-col {padding: 0;}

.modal-window__footer-col--right {margin-left: auto;}

.modal-window__body {
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
}

.modal-block {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 100%;
	z-index: 100;
	background-color: rgba(255,255,255,0.8);
	overflow: hidden;
	opacity: 0;
    transition: bottom 0.1s ease 0.4s, opacity 0.2s ease 0.2s;
}

.modal-block.is-active {
	bottom: 0;
	opacity: 1;
    transition: bottom 0.1s ease 0s, opacity 0.2s ease 0.1s;
}

.modal-block__content {
	position: absolute;
	bottom: 100%;
	left: 50%;
	width: 90%;
    max-height: 95vh;
	padding: 2rem;
	text-align: center;
	background-color: #fff;
	box-shadow: 0 3px 60px 10px rgba(0,0,0,0.1);
    overflow-y: auto;
    transition: all 0.3s ease 0s;
    transform: translate3d(-50%,0,0);
}

.modal-block.is-active .modal-block__content {
	bottom: 50%;
    transition-delay: 0.2s;
    transform: translate3d(-50%,50%,0);
}

.modal-block__body {
    padding-bottom: 1rem;
	text-transform: uppercase;
	color: #666;
}

.modal-block__image {margin-bottom: 1rem;}

.modal-block__text {font-size: 0.875em;}

.modal-block__close {
	position: absolute;
	top: 0.5rem;
	right: 0.5rem;
	color: #999;
	width: 1.5rem;
	height: 1.5rem;
	padding: 0.25rem;
	cursor: pointer;
}

.modal-block__footer-row {margin-bottom: 1rem;}

.modal-block__footer-row:last-child {margin-bottom: 0;}

.modal-block__submit {
	position: relative;
	display: inline-block;
	vertical-align: top;
	min-width: 10rem;
	height: 2.5rem;
	padding: 0.5rem 1.5rem;
	font: 400 1.125rem/1.5rem 'Minion Pro', serif;
	color: #fff;
	text-align: center;
	background-color: #b1a082;
	cursor: pointer;
    transition: all 0.3s;
}

.modal-block__submit:hover {background-color: #7f7462;}

.modal-block__submit::after {
	content: '';
	position: absolute;
	top: 50%;
	right: -33px;
	display: block;
	width: 45px;
	height: 10px;
	margin-top: -1px;
	background: url(../images/readmore-arrow.svg) no-repeat right center;
	transform: translateY(-50%);
	transition: all 0.3s;
}

/* END - МОДАЛЬНЫЕ БЛОКИ */

/* BEGIN - ТАБЛИЦА БРЕНДОВ */

.brand-list {
    margin: 0 -1rem;
    white-space: nowrap;
    overflow: hidden;
}

.brand-list__item {
    display: inline-block;
    vertical-align: top;
    width: 14.25rem;
    margin: 0 10px;
    border: 1px solid #e7e7e7;
    text-align: center;
    white-space: normal;
}

.brand-list__inner {
    position: relative;
    display: block;
    width: 100%;
    padding-bottom: 56.14%;
    overflow: hidden;
}

.brand-list__image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
	height: 100%;
	background-color: #fff;
    transition: all 0.4s ease;
}

.brand-list__image--front {
    z-index: 1;
	backface-visibility: hidden;
	transform-style: preserve-3d;
	transform: rotateY(0deg);
}

.brand-list__item:hover .brand-list__image--front {
    opacity: 0;
    transform: rotateY(180deg);
}

.brand-list__image--back {
    opacity: 0;
    z-index: 0;
    backface-visibility: hidden;
    transform-style: preserve-3d;
    transform: rotateY(-180deg);
}

.brand-list__item:hover .brand-list__image--back {
    opacity: 1;
    z-index: 2;
    transform: rotateY(0deg);
}

.brand-list__image img {position: absolute;}

.brand-list__image img {
    top: 50%;
    left: 50%;
    max-width: 90%;
    transform: translate3d(-50%,-50%,0);
}

.brand-list__image--back img {
    top: 0;
    left: 0;
    max-width: none;
    max-height: none;
    width: 100%;
    height: 100%;   
    transform: none;
}

/* END - ТАБЛИЦА БРЕНДОВ */


/* BEGIN- КНОПКА ВВЕРХ */

.moveup {
    position: fixed;
    top: 50%;
    right: 1rem;
    z-index: 9;
    display: inline-block;
    vertical-align: top;
    width: 3rem;
    height: 3rem;
    margin-top: -1.5rem;
    padding: 0.75rem;
    color: #fff;
    background-color: #CCBCA1;
    border-radius: 50%;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease;
    user-select: none;
}

.moveup:hover {background-color: #b1a082;}

.moveup.is-shown {
    opacity: 1;
    visibility: visible;
}

.moveup__icon {
    display: inline-block;
    vertical-align: top;
    width: 1.5rem;
    height: 1.5rem;
    margin-top: -2px;
    transform: rotate(-90deg);
}

/* END - КНОПКА ВВЕРХ */