@import "base-vars";

@media (max-width: 1023px) {

  .modal-window__body {
    padding: 0;
  }

  .notepad__products-name,
  .notepad__products-info {
    padding-left: 0;
  }

  .textile-brand__gallery {
    margin-right: -1rem;
    margin-left: -1rem;
    white-space: nowrap;
    overflow: hidden;
  }

  .textile-brand__gallery-item {
    display: inline-block;
    vertical-align: top;
    float: none;
    margin-left: 1rem;
    padding: 0;
  }

  .textile-brand__gallery .slick-list {
    padding-right: 2rem;
  }

  .textile-brand__gallery.slick-initialized .textile-brand__gallery-item {
    display: block;
    float: left;
  }

}

@media (min-width: 768px) {

  .notepad__products-name,
  .notepad__products-detail {
    font-size: 0.875em;
  }

  .modal-block__content {
    min-width: 30rem;
    width: auto;
  }

  .modal-block__body {
    padding: 2rem;
  }

  .modal-block__text {
    font-size: 1em;
  }
}

@media (min-width: 1024px) {

  .form__group {
    margin-bottom: 2.5rem;
  }

  .form__group-label {
    font-size: 1.125rem;
  }

  .form__group-label--small,
  .form__group-input:focus + .form__group-label {
    font-size: 0.875rem;
  }

  .pagination {
    margin-top: 3.5rem;
  }

  .tabs {
    margin: 0;
  }

  .tabs__header {
    margin-bottom: 3rem;
  }

  .tabs__header-list {
    padding: 0;
    display: flex;
  }

  .tabs__header-item {
    margin: 0;
    font-size: 1em;
    flex-grow: 1;
  }

  .tabs__header-link {
    width: 100%;
    min-width: 17.5rem;
  }

  .tabs__header-link:hover,
  .tabs__header-link.is-active {
    box-shadow: none;
    background-color: #7f7462;
  }

  .tabs__item {
    padding: 0;
  }

  .brand-list {
    margin-left: 0;
    margin-right: 0;
    border-top: 1px solid #e7e7e7;
    border-left: 1px solid #e7e7e7;
    white-space: normal;
  }

  .brand-list__item {
    width: 20%;
    margin: 0;
    padding: 0;
    border-width: 0 1px 1px 0;
  }

  .grid-list__item {
    display: inline-block;
    vertical-align: top;
  }

  .modal-window.is-active {
    padding-top: 3.75rem;
  }

  .modal-window--has-footer.is-active {
    padding-bottom: 2.5rem;
  }

  .modal-window__header {
    padding: 1rem 0 0.75rem;
    text-align: center;
    font-size: 2em;
  }

  .modal-window__close .icon {
    padding: 0.5rem;
  }

  .modal-window__footer {
    padding-top: 0;
    padding-bottom: 0;
  }

  .modal__footer-col--right {
    margin-left: auto;
  }

  .main {
    padding-bottom: 3.5rem;
  }

  .notepad .modal-window__body {
    overflow: hidden;
  }

  .notepad__form {
    position: absolute;
    top: 2rem;
    bottom: 2rem;
    left: 10px;
    width: 45%;
    padding-right: 3.5rem;
    border-right: 1px solid #f3f2f2;
  }

  .notepad__products {
    width: 50%;
    height: 100%;
    margin-left: 50%;
    padding-right: 0;
    overflow-y: auto;
  }

  .notepad__products-list {
    padding-right: 2rem;
  }

  .notepad__products-item {
    padding-bottom: 2rem;
    border-bottom: 2px solid #f9f9f9;
  }

  .notepad__products-item:last-child {
    padding-bottom: 0;
    border-bottom: 0;
  }

  .notepad__products-inner {
    display: flex;
    flex-wrap: wrap;
  }

  .notepad__products-image,
  .notepad__products-info {
    order: 1;
  }

  .notepad__products-name {
    min-height: 1em;
    margin-left: 2rem;
    margin-bottom: 1rem;
    font-size: 1em;
  }

  .notepad__products-detail {
    font-size: 1em;
    margin-top: -2px;
  }

  .notepad__products-remove {
    left: 0.25rem;
    top: -2px;
  }

  .page-cart__table {
    margin: 0 0 3.5rem;
    padding: 0;
    border: 0;
  }

  .page-cart__table-row {
    padding: 0 2rem 1.5rem 0;
    margin: 0 0 1.5rem;
    flex-wrap: nowrap;
  }

  .page-cart__table-row.page-cart__table-row--title {
    padding-bottom: 1rem;
    line-height: 1;
  }

  .page-cart__table-row--title .page-cart__table-col--price {
    padding-top: 0;
  }

  .page-cart__table-col--image {
    padding-left: 0;
  }

  .page-cart__table-col--name {
    font-size: 0.875em;
    max-width: none;
    width: 30%;
    padding: 0 1rem;
  }

  .page-cart__table-col--price {
    float: none;
    width: 33.333%;
    text-align: center;
  }

  .page-cart__table-col--price,
  .page-cart__table-col--amount {
    float: none;
    width: 33.333%;
    text-align: center;
  }

  .page-cart__table-col--amount {
    margin-left: 0;
  }

  .page-cart__table-col--remove {
    top: 50%;
    left: auto;
    padding-right: 0;
    right: 0;
    margin-top: -1.5rem;
  }

  .page-cart__table-detail {
    max-width: none;
    width: 50%;
    margin: 0;
  }

  .page-cart__table-total {
    margin: 0;
    padding: 0;
    text-align: right;
  }

  .page-cart__table-total-name,
  .page-cart__table-total-price {
    display: inline;
    width: auto;
  }

  .page-cart__table-total-name {
    margin-right: 1em;
  }

  .page-cart__delivery {
    margin-bottom: 2.5rem;
    padding-bottom: 2.5rem;
    border-bottom: 1px solid #e7e7e7;
  }

  .page-cart__payment {
    margin-bottom: 3.5rem;
  }

  .page-cart__form-price {
    font-size: 1.222em;
  }

  .bill-list {
    margin: 0;
    border: 0;
  }

  .bill-list__item {
    margin-bottom: 1.5rem;
    padding: 0;
    border: 0;
  }

  .bill-list__item:last-child {
    margin-bottom: 0;
  }

  .bill-list__name {
    margin: 0 0;
  }

  .bill-list__description,
  .bill-list__description.is-active {
    max-height: none;
    margin: 0.5rem 0 0 2.25rem;
    opacity: 1;
  }

  .bill-list__description-inner {
    padding: 0;
    background: none;
  }

  .page-order__notice {
    margin-bottom: 3.5rem;
  }

  .page-order__section {
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: 0;
  }

  .page-order__section--payment,
  .page-order__section--contact {
    padding-right: 2rem;
  }

  .page-order__section--delivery,
  .page-order__section--product {
    padding-left: 2rem;
  }

  .page-order__section--delivery {
    margin-top: 2rem;
  }

  .page-order__section--contact,
  .page-order__section--product {
    padding-top: 3.5rem;
    margin-top: 3.5rem;
    border-top: 1px solid #e1e1e1;
  }

  .page-order__section-frame {
    margin-top: 0.5rem;
    padding: 1.5rem;
    outline: 0.5rem solid #f0f0f0;
  }

  .page-order__product-detail {
    margin-top: 0.25rem;
    text-align: right;
  }

  .page-header--textile-brand {
    display: block;
    height: auto;
    margin: 2rem 0;
    text-align: center;
  }

  .page-header--textile-brand .page-header__text {
    padding-bottom: 0;
  }

  .page-header--textile-brand .page-header__description {
    max-width: none;
    margin-top: 0.5rem;
  }

  .textile-brand__section {
    margin-bottom: 2.5rem;
    padding-bottom: 3rem;
    border-bottom: 1px solid #f1eee9;
    display: flex;
    flex-direction: column;
  }

  .textile-brand__section:last-child {
    padding-bottom: 0;
    border-bottom: 0;
  }

  .textile-brand__section-title {
    margin-bottom: 1rem;
    text-align: center;
    font-size: 1.375em;
  }

  .textile-brand__section-description {
    width: 50rem;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    order: 1;
  }

  .textile-brand__gallery {
    margin: 10px -10px 0;
    order: 2;
  }

  .textile-brand__gallery-item {
    float: none;
    display: inline-block;
    vertical-align: top;
    margin-top: 20px;
    padding: 0 10px;
  }
  
  .page-reviews__list {
    white-space: normal;
    overflow: visible;
    counter-reset: list1;
  }

  .page-reviews__item {
    position: relative;
    padding: 2rem 0;
    margin-bottom: 3.75rem;
    display: flex;
    align-items: center;
  }

  .page-reviews__item:last-child {
    margin-bottom: 0;
  }

  .page-reviews__item::before {
    content: '';
    position: absolute;
    top: 0;
    left: 2rem;
    right: 2rem;
    bottom: 0;
    display: block;
    border: 8px solid #ccbca1;
  }

  .page-reviews__item::after {
    content: counter(list1, decimal-leading-zero);
    counter-increment: list1;
    position: absolute;
    top: 1.5rem;
    right: -0.5rem;
    width: 5rem;
    padding: 1.75rem 0 1rem;
    text-align: center;
    font: 400 82px/1 'Minion Pro', serif;
    color: #f2f0eb;
    background-color: #fff;
  }

  .page-reviews__image {
    position: relative;
    width: 33.333%;
    margin: 0;
    direction: rtl;
  }

  .page-reviews__info {
    position: relative;
    width: 60%;
    padding-left: 2rem;
    background-color: #fff;
  }

  .page-reviews__title,
  .page-reviews__subtitle {
    font-size: 1em;
  }

  .page-reviews__subtitle {
    margin: 0;
  }

  .page-reviews__description {
    padding: 0;
    border: 0;
  }

  .page-reviews__readmore {
    margin-top: 1rem
  }

  .concierge-banner {
    background: url(../images/concierge-banner-bg.jpg) no-repeat center;
    background-size: cover;
  }

  .concierge-banner__image {
    float: right;
  }

  .concierge-banner__image::before {
    display: none;
  }

  .concierge-banner__content {
    position: relative;
    top: auto;
    padding-top: 3.5rem;
    padding-bottom: 3.5rem;
    transform: none;
  }

  .concierge-banner__title {
    margin-bottom: 1rem;
    font-size: 2em;
    opacity: 0;
    transform: translate3d(0, -5rem, 0);
    transition: all 0.4s ease 0.4s;
  }

  .concierge-banner__description {
    opacity: 0;
    transform: translate3d(0, 5rem, 0);
    transition: all 0.4s ease 0.6s;
  }

  .concierge-banner__image {
    opacity: 0;
    transform: translate3d(5rem, 0, 0);
    transition: all 0.4s ease 0.2s;
  }

  .concierge-banner.is-on-screen .concierge-banner__image,
  .concierge-banner.is-on-screen .concierge-banner__title,
  .concierge-banner.is-on-screen .concierge-banner__description {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

}

@media (min-width: 1024px) and (max-width: 1279px) {
  .grid-list {
    margin-left: -10px;
    margin-right: -10px;
  }
}

@media (min-width: 1280px) {

  .tabs__header {
    margin-bottom: 3.5rem;
  }

  .grid-list {
    margin-bottom: -3.5rem;
  }

  .grid-list__item {
    margin-bottom: 3.5rem;
  }

  .amount__label {
    font-size: 1em;
  }

  .modal-window.is-active {
    top: 3.5rem;
  }

  .modal-window--has-footer.is-active {
    padding-bottom: 3rem;
  }

  .modal-window__footer-button {
    height: 3rem;
    line-heght: 1.75rem;
  }

  .pagination {
    margin-top: 4rem;
  }

  .moveup {
    right: 100px;
  }

  .main {
    padding-bottom: 4rem;
  }

  .notepad__form {
    left: 1rem;
    padding-right: 4.5rem;
  }

  .notepad__products-remove {
    left: 0.75rem;
  }

  .page-cart__table {
    margin-bottom: 6.25rem;
  }

  .page-cart__table-row {
    padding-bottom: 2rem;
    margin-bottom: 2rem;
  }

  .page-cart__table-col--name {
    font-size: 1em;
  }

  .page-cart__table-col--price {
    padding-top: 0.5rem;
  }

  .page-cart__table-col--remove {
    margin-top: -2rem;
  }

  .page-cart__table-col--remove .icon {
    padding: 3px;
  }

  .page-cart__table-total-name {
    font-size: 1.375em;
  }

  .page-cart__payment {
    margin-bottom: 6rem;
  }

  .page-header--textile-brand {
    margin-top: 5rem;
    margin-bottom: 3rem;
    background: none;
  }

  .page-reviews__info {
    width: 50%;
  }

  .page-reviews__title,
  .page-reviews__subtitle {
    font-size: 1.125em;
  }

  .page-reviews__item::before {
    left: 7.25rem;
    right: 7.25rem;
  }

  .page-reviews__item::after {
    right: 5rem;
  }

  .concierge-banner {
    margin-bottom: -2.5rem;
  }

}