.slick-slider {
  position: relative;
  display: block;
  user-select: none;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
}

.slick-track:before,
.slick-track:after {
  display: table;
  content: '';
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}

.slick-slide:focus {
  outline: none;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
}

.slick-dots {
  margin-top: 1rem;
  text-align: center;
  line-height: 1;
  display: flex;
  align-items: center;
}

.slick-dots::before,
.slick-dots::after {
  content: '';
  position: relative;
  display: block;
  width: auto;
  height: 1px;
  background-color: #f0f0f0;
  flex-grow: 1;
}

.slick-dots::before {
  margin-right: 0.5rem;
}

.slick-dots::after {
  margin-left: 0.5rem;
}

.slick-dots li {
  position: relative;
  display: inline-block;
  vertical-align: top;
  width: 24px;
  height: 24px;
  margin: 0 2px;
}

.slick-dots button {
  position: relative;
  z-index: 1;
  display: block;
  width: 100%;
  height: 100%;
  font-size: 0;
}

.slick-dots button::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  width: 10px;
  height: 10px;
  margin-top: -5px;
  margin-left: -5px;
  border: 2px solid rgba(110, 110, 110, 0.55);
  border-radius: 50%;
  transform: all 0.3s ease
}

.slick-dots li:hover button::before,
.slick-dots li.slick-active button::before {
  border-color: #b1a082;
  background-color: #b1a082;
}

.slick-carousel {
  margin-left: -1rem;
  margin-right: -1rem;
  overflow: hidden;
  white-space: nowrap;
}

.slick-carousel.slick-initialized {
  overflow: visible;
  white-space: normal;
}

.slick-carousel .slick-list {
  padding-right: 3rem;
}

.slick-carousel .slick-carousel__item {
  display: inline-block;
  vertical-align: top;
  float: none;
  padding-bottom: 1px;
  white-space: normal;
}

.slick-carousel.slick-initialized .slick-carousel__item {
  display: block;
  float: left;
  padding-left: 0;
  padding-right: 0;
  margin: 0 1rem;
}


@media (min-width: 1024px) {

  .slick-dots {
    position: relative;
    margin: 2rem 1rem 0;
  }

  .slick-dots::before,
  .slick-dots::after {
    background-color: #e7e7e7;
  }

  .slick-dots::before {
    margin-right: 2.5rem;
  }
  .slick-dots::after {
    margin-left: 2.5rem;
  }

  .slick-dots li {
    width: 24px;
    height: 24px;
    padding: 3px;
    margin: 0;
    overflow: hidden;
    border-radius: 50% 0 50% 50%;
  }

  .slick-dots li::after {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 50%;
    height: 50%;
    background-color: #fff;
  }

  .slick-dots li::before {
    content: '';
    position: absolute;
    left: 100%;
    top: 50%;
    display: block;
    width: 24px;
    height: 24px;
    background: #b1a082;
  }

  .slick-dots li.slick-active::before {
    animation: dotborder 0.3s ease 1 forwards;
  }

  @keyframes dotborder {
    0% {
      left: 100%;
      top: 50%;
    }
    33% {
      left: 50%;
      top: 50%;
    }
    66% {
      left: 0;
      top: 50%;
    }
    100% {
      left: 0;
      top: 0;
    }
  }

  .slick-dots button {
    background-color: #fff;
    border-radius: 50%;
  }

  .slick-dots button::before {
    width: 12px;
    height: 12px;
    margin-top: -6px;
    margin-left: -6px;
    border-color: #71706f;
    transform: all 0.3s ease
  }

  .slick-arrow {
    position: absolute;
    top: 50%;
    z-index: 1;
    display: block;
    width: 2.5rem;
    height: 2.5rem;
    padding: 0.5rem;
    margin-top: -1.25rem;
    color: #ababab;
  }

  .slick-prev {
    right: 100%;
  }
  .slick-next {
    left: 100%;
  }

  .slick-carousel .slick-list {
    padding-right: 0;
  }

}

@media (min-width: 1024px) and (max-width: 1279px) {
  .slick-carousel {
    margin-left: -10px;
    margin-right: -10px;
  }

  .slick-carousel.slick-initialized .slick-carousel__item {
    margin-left: 10px;
    margin-right: 10px;
  }
}