*,
:before,
:after {
    box-sizing: border-box;
}

html,
body,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
acronym,
address,
code,
img,
small,
sub,
sup,
tt,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
figure,
figcaption {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  background: transparent;
}

time {
  display: inline
}

th {
  text-align: left;
  color: inherit;
}

q,
blockquote {
  quotes: none;
}

q:before,
q:after,
blockquote:before,
blockquote:after {
  content: '';
  content: none;
}

ul,
ol {
  list-style: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

address {
  font-style: normal;
}

img,
svg {
  max-width: 100%;
  max-height: 100%;
  vertical-align: top;
}

input,
button,
select,
textarea,
optgroup {
  background: none;
  border: 0;
  padding: 0;
  margin: 0;
  font: inherit;
  color: inherit
}
button,
input[type="submit"] {
  cursor: pointer
}

select {
  max-width: 100%
}
select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #000
}

a {
    color: inherit;
    text-decoration: none
}

a:focus,
img:focus,
input:focus,
button:focus,
select:focus,
textarea:focus {
    outline: 0
}

input::-moz-focus-inner,
button::-moz-focus-inner {
    outline: 0;
    border: 0;
    padding: 0
}

input[type="search"] {
    -webkit-appearance: textfield
}

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
    -webkit-appearance: none
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    height: auto
}

input[type="submit"],
button[type="submit"] {
    -webkit-appearance: none
}

input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
    box-shadow: inset 0 0 0 50px #fff
}