@import "base-vars";

.footer {
  text-align: center;
  
  &__container {
    padding: 0;
  }
  
  &__bottom {
    padding-left: 0;
    padding-right: 0;
  }
  
  &__social-icon {
    vertical-align: top;
  }
  
  &__copy {
    padding: 0.25rem 0;
    font-size: 0.9375em;
    font-weight: 400;
    line-height: 2.5rem;
  }
  
}

@media (max-width: 1023px) {

  .footer__social {
    background-color: rgba(231,231,231,0.8);
  }
  
}

@media (min-width: 1024px) {
  
  .footer__container {
    display: flex;
    align-items: center;
  }
  
  .footer__top {
    background-color: rgba(231,231,231,0.8);
  }

  .footer__menu {
    margin-left: -0.75rem;
    font-size: 0.8125em;
    font-weight: 600;
    
    &-link {
      padding: 0 0.75rem;
      line-height: 2.5rem;
      &:hover, &.is-active {
        color: #7f7462;
        box-shadow: inset 0 2px #7f7462;
      }
    }
  }

  .footer__contact {
    margin-left: auto;
    
    &-phone {
      font-size: 1.125em;
      font-weight: 400;
    }
    
    &-address {
      font-weight: 400;
      font-size: 0.875em;
      margin-right: 1.25rem;
    }
  }

  .footer__social {
    order: 1;
    margin-left: auto;
    margin-right: -0.5rem;
  }
  
  .footer__copy {
    padding-top: 0;
    padding-bottom: 0;
    line-height: 1;
  }
  
}

@media (min-width: 1280px) {
  
  .footer {
    margin-top: 2.5rem;
  }

  .footer__top {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9;
  }

  .last-screen .footer__top {
    position: absolute;
    bottom: 2.5rem;
  }
  
  .footer__bottom {
    background-color: #CCBCA1;
  }
  
}