/*Превью проекта*/

@import "base-vars";

.project-list__item {
  padding-bottom: 1px;
  text-align: center;
}

.project-list__name {
  margin-top: 1rem;
  font-size: 0.9375em;
  line-height: 1.2;
}

@media (min-width: 768px) and (max-width: 1023px) {
  
  .project-list--static .project-list__item {
    display: inline-block;
    vertical-align: top;
    width: 50%;
  }
}

@media (min-width: 1024px) {
  
  .project-list__image {
    position: relative;
    overflow: hidden;
    
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
      display: block;
      width: 100%;
      height: 100%;
      background-color: rgba(0,0,0,0.7);
      opacity: 0;
      transition: $base-transition;
    }
  }
  
  .project-list__readmore {
    position: absolute;  
    top: -100%;
    left: 50%;
    z-index: 3;
    transform: translate3d(-50%,-50%,0);
  }
  
  .project-list__card:hover {
    
    .project-list__readmore {
      top: 50%;
    }
    
    .project-list__image::before {
      opacity: 1;
    }
  }
  
}

@media (min-width: 1280px) {

  .project-list__name {
    margin-top: 1.25rem;
    font-size: 1em;
  }
  
}