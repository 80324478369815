/*Галерея*/

@import "base-vars";

.gallery {
  overflow: hidden;
  
  .slick-arrow {
    position: absolute;
    top: 50%;
    z-index: 1;
    margin: 0;
    color: #fff;
    background-color: rgba(51,51,51,0.4);
    transform: translateY(-50%);
    transition: $base-transition;
    
    &:hover {
      background-color: rgba(51,51,51,0.75);
    }
  }
  
  .slick-prev {
    left: 0;
    right: auto;
  }
  
  .slick-next {
    left: auto;
    right: 0;
  }
}

.gallery__main {
  white-space: nowrap;
  overflow: hidden;
  
  &-item {
    position: relative;
    display: inline-block;
    vertical-align: top;
    width: 100%;
    
    .image-frame {
      cursor: pointer;
      
      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        display: block;
        width: 100%;
        height: 100%;
        opacity: 0;
        background: url(../images/gallery-zoom.svg) no-repeat center;
        background-size: 100px;
        transition: opacity 0.3s ease;
      }
    }
    
    &:hover .image-frame::after {
      background-color: rgba(255, 255, 255, 0.15);
      opacity: 0.5;
    }
  }
  
  &.slick-initialized {
    overflow: visible;
    white-space: normal;
    
    .gallery__main-item {
      float: left;
      width: auto;
    }
  }
  
  .slick-arrow {
    width: 2.5rem;
    height: 4.5rem;
  }
  
  .slick-prev {
    padding-left: 0.25rem;
    padding-right: 0.75rem;
    border-radius: 0 4.5rem 4.5rem 0;
  }
  
  .slick-next {
    padding-left: 0.75rem;
    padding-right: 0.25rem;
    border-radius: 4.5rem 0 0 4.5rem;
  }
}

.gallery__nav {
  position: relative;

  &-list {
    margin-top: 4px;
    margin-left: -2px;
    margin-right: -2px;
    white-space: nowrap;
    overflow: hidden;
    
    &.slick-initialized {
      white-space: normal;
      overflow: visible;
    }
  }
  
  &-item {
    position: relative;
    display: inline-block;
    vertical-align: top;
    padding: 0 2px;
    cursor: pointer;
    transition: $base-transition;
    
    .slick-initialized & {
      display: block;
      float: left;
      padding: 0;
      margin: 0 2px;
    }

    &:hover {
      opacity: 0.75;
    }
  }
  
  .slick-arrow {
    width: 1.75rem;
    height: 3rem;
    padding: 0.75rem 0;
  }
  
  .slick-prev {
    padding-right: 0.25rem;
    border-radius: 0 3rem 3rem 0;
  }
  
  .slick-next {
    padding-left: 0.25rem;
    border-radius: 3rem 0 0 3rem
  }
}