/*Набор шаблонных классов*/

.is-animated {
  transition: all 0.3s ease;
}

.is-absolute {
  position: absolute;
}

.is-relative {
  position: relative;
}

.is-fixed {
  position: fixed;
}

.is-block {
  display: block;
}

.is-inline-block {
  display: inline-block;
  vertical-align: top;
  float: none;
  &--middle {
    vertical-align: middle;
  }
}
.is-table {
  display: table;
}

.is-flex {
  display: flex;
  &--vertical {
    flex-direction: column;
  }
  &--wrap {
    flex-wrap: wrap;
  }
  &--center {
    justify-content: center;
    align-items: center;
  }
  &--centerX {
    justify-content: center;
  }
  &--centerY {
    align-items: center;
  }
}