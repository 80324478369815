/*Страница контактов*/

@import "base-vars";

.feedback-card__item {
  margin: 0 -1rem 2rem;
  padding: 0 1rem 2rem;
  border-bottom: 1px solid #ececec;
}

.feedback-card__item:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: none;
}

.feedback-card__title {
  margin-bottom: 1rem;
}

.feedback-card__description {
  margin-bottom: 1rem;
  font-weight: 400;
  font-size: 0.8125em;
  line-height: 1.71;
}

.feedback-card__contacts-item {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  white-space: nowrap;
  border-left: 1px solid #f1f1f1;
}

.feedback-card__contacts-item:nth-child(2n+1) {
  border: 0;
}

.feedback-card__contacts-label { 
  font-size: 0.75em;
}

.feedback-card__contacts-value {
  font-size: 0.875em;
}

.feedback-map__container {
  position: relative;
  width: 100%;
  height: 260px;
  background-color: #eee;
}

.feedback-map img,
.feedback-map svg {
  vertical-align: top;
  max-width: none;
  max-height: none;
}

@media (max-width: 1023px) {
 
  .feedback-map {
    margin-top: 2rem;
    padding: 0;
  }
  
}

@media (min-width: 1024px) {
  
  .feedback-card__description {
    margin-bottom: 1.5rem;
    font-size: 0.875em;
  }

  .feedback-card__item {
    margin-left: 0;
    margin-right: 0;
    padding-left: 0;
    padding-right: 0;
  }

  .feedback-card__contacts {
    margin-top: -1rem;
  }

  .feedback-card__contacts-item {
    margin-top: 1rem;
    padding-top: 0;
    padding-bottom: 0;
    border: 0;
  }

  .feedback-card__contacts-label {
    font-size: 0.8125em;
  }
  
  .feedback-card__contacts-value {
    font-size: 1.125em;
  }

  .feedback-map__container {
    height: 500px;
  }
  
}

@media (min-width: 1280px) {
  
  .feedback-card {
    padding-right: 2rem;
  }
  
}