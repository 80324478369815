/*Странице категории*/

@import "base-vars";

.page-header--category {
  margin-bottom: 1rem;
  
  .page-header__title {
    font-size: 2em;
  }
}

.search-form {
  width: 100%;
  max-width: 30rem;
  margin-bottom: 4rem;
}

.search-form__input {
  display: block;
  width: 100%;
  height: 2.75rem;
  padding: 0.5rem 3rem 0.5rem 1.5rem;
  font: 300 15px/1.75rem Calibri, sans-serif;
  color: #666;
  outline: 1px solid #cecece;
  
  &:focus {
    outline: 1px solid #cecece;
  }
  
  &::placeholder {
    color: #ccc;
    opacity: 1;
  }
  
  &:focus::placeholder {
    color: #ccc;
    opacity: 0;
  }
}

.search-form__button {
  top: 2px;
  right: 0;
}

.subcategories-grid {
  margin: 0 -1rem 2.5rem;

  &__item {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }
  
  &__image {
    position: relative;
    display: block;
    width: 100%;
    height: 9.75rem;
    
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: block;
      background-color: rgba(0, 0, 0, 0.3);
      transition: all 0.3s ease;

      .subcategories-grid__item:hover & {
        background-color: rgba(0, 0, 0, 0.75);
      }
    }

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      min-height: 100%;
      min-width: 100%;
      max-height: 200%;
      transform: translate3d(-50%,-50%,0);
    }
  }

  &__link {
    display: block;
    overflow: hidden;
  }
  
  &__name {
    padding: 0.75rem 2rem;
    font-size: 1.125em;
    font-family: $font-serif;
    color: #fff;
    background-color: $color-main;
    transition: $base-transition;
  }
  
  &__icon {
    opacity: 0;
    color: #b1aa9e;
    transition: $base-transition;
  }
}


@supports (object-fit: cover) {
  .subcategories-grid__image img {
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    min-height: 0;
    min-width: 0;
    max-height: none;
    object-fit: cover;
    transform: none;
  }
}

.subcategories-grid.slick-slider {
  
  .subcategories-grid__item {
    padding: 0;
  }
  
  .subcategories-grid__link {
    width: 85vw;
    max-width: 400px;
    padding: 0 0.75rem;
  }
}

.subcategories-grid__item:hover,
.subcategories-grid__item.slick-active {
  
  .subcategories-grid__name {
    background-color: #7f7462;
  }
  
  .subcategories-grid__icon {
    opacity: 1;
    transform: translateX(0.5em);
  }
}

.subcategories-list {
  margin-top: -0.5rem;
  margin-bottom: 1rem;
  
  &__item {
    display: inline-block;
    vertical-align: top;
    margin-right: 0.5rem;
    margin-top: 0.5rem;
  }
  
  &__link {
    display: block;
    padding: 0.25rem 1rem;
    font-weight: 400;
    font-size: 0.875em;
    line-height: 1.5rem;
    border: 1px solid $color-text-gray;
    border-radius: 4px;
    transition: $base-transition;
    
    &:hover, &--active {
      color: #fff;
      background-color: $color-main-dark;
      border-color: $color-main-dark;
    }
  }
}

.page-category__toolbar {
  margin: 0 -1rem 1.5rem;
  font-weight: 400;
  font-size: 0.875em;
  
  &-inner {
    padding: 1rem;
    border-top: 1px solid $color-bg-gray;
    border-bottom: 1px solid $color-bg-gray;
  }
}

.page-category__stock-title {
  margin-right: 1.25rem;
}

.page-category__stock-content {
  flex-grow: 1;
}

.category-description {
  margin-top: 4rem;
}

.loadmore {
  position: relative;
  margin-top: 2.5rem;
  margin-bottom: -1.5rem;
  
  &__button.is-active .icon {
    animation: cycle 1.5s infinite linear;
  }
}

@keyframes cycle {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media (max-width: 1023px) {
  
  .subcategories-grid__name--single {
    border-bottom: 3px solid rgba(0, 0, 0, 0);
    
    .icon {
      display: none;
    }
    
    .icon-text {
      margin: 0 auto;
    }
    
    .subcategories__item.slick-active & {
      background-color: $color-main;
      border-color: #7f7462;
    }
  }
  
  .page-category__toolbar-item {
    margin-bottom: 1.5rem;
    
    &:last-child {
      margin-bottom: 0;
    }
  }
  
}

@media (min-width: 1024px) {
 
  .subcategories-grid {
    margin: -3.5rem -0.25rem 3.5rem;
    
    &__item {
      padding-left: 0.25rem;
      padding-right: 0.25rem;
    }
  }
  
  .subcategories-list {
    margin-bottom: 1.25rem;
    text-align: center;
  }
  
  .page-category__toolbar {
    position: relative;
    z-index: 4;
    margin-left: 0;
    margin-right: 0;
    
    &-inner {
      display: flex;
      align-items: center;
      padding-left: 0;
      padding-right: 0;
    }
    
    &-item {
      margin: 0;
      
      &--filter {
        flex-grow: 1;
      }

      &--stock {
        padding-left: 1rem;
        margin-left: auto;
        margin-bottom: auto;
      }
    }
    
  }
  
  .page-category__stock-title {
    margin-right: 0.5rem;
  }
  
  .page-category__stock-content {
    width: 11.25rem;
  }

}

@media (min-width: 1280px) {
  
  .subcategories-list {
    margin-bottom: 1.5rem;
  }
  
  .subcategories-grid {
    margin-top: -4rem;
    margin-bottom: 4rem;
    
    &__name {
      padding-left: 2.5rem;
      padding-right: 2.5rem;
    }
  }
  
  .page-category__toolbar {
    height: 4rem;
    
    &-wrap {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      background-color: rgba(255,255,255,0.95);
    }

    &-inner {
      width: 71.25rem;
      margin-left: auto;
      margin-right: auto;
      padding-top: 0.75rem;
      padding-bottom: 0.75rem;
    }
    
    &--fixed &-wrap {
      position: fixed;
      top: 6.75rem;
      z-index: 10;
      box-shadow: 0 2px 21px rgba(0,0,0,0.15);
    }
    
    &--fixed &-inner {
      border: 0;
    }
  }
  
  
  .loadmore {
    margin-top: 4rem;
    margin-bottom: -2.5rem;
  }
  
}