/*Страница товара*/

@import "base-vars";

.product-sticker {
  top: 0;
  left: 0;
  z-index: 1;
  min-width: 7.5rem;
  padding: 5px 0.5rem;
  text-align: center;
  white-space: nowrap;
  user-select: none;
  
  .page-product__image * {
    left: 1rem;
  }
  
  &--new {
    background-color: #7cab94;
  }
  
  &--hit {
    background-color: #b1a082;
  }
  
  &--sale {
    background-color: #b1a082;
  }
}

.page-product {
  position: relative;
  padding-top: 1.5rem;
  padding-bottom: 4rem;
  
  &__title {
    margin-top: -0.5rem;
    margin-bottom: 1.5rem;
  }
  
  .path--inline .path__item:nth-last-child(1) {
    display: none;
  }
  
  .path--inline .path__item:nth-last-child(2) {
    margin: 0;
    
    &::after, &::before {
      display: none;
    }
  }
}

.page-product__image .slick-dots li:hover button::before,
.page-product__image .slick-dots li.slick-active button::before {
  background-color: #333;
  border-color: #333;
}

.page-product__card {
  position: static;
}

.page-product__card-price {
  margin-top: 1.5rem;
  margin-bottom: 0.5rem;
  white-space: normal;
  
  .price__item--status {
    display: inline-block;
    vertical-align: top;
    margin-right: 1.25rem;
    line-height: 1.5rem;
  }
}

.page-product__card-info {
  margin: -0.5rem -1rem 2rem;
  line-height: 1.25;
  
  &-item {
    display: inline-block;
    vertical-align: top;
    margin-top: 0.5rem;
    padding: 0 1rem;
    
    &:last-child {
      margin-bottom: 0;
    }
  }
  
  &-title, &-value {
    display: inline-block;
    vertical-align: top;
    white-space: nowrap;
  }
  
  &-title {
    margin: 0 0.5em;
    font-size: 0.8125em;
    color: #999;
  }
  
  &-value {
    font-family: $font-serif;
  }
}


.page-product__card-form {
  margin-bottom: 2rem;
}

.page-product__card-bestprice {
  margin: 0.75rem 0;
  color: #ec531b;
  
  .icon {
    width: 2rem;
    height: 2rem;
  }
  
  .icon-text {
    margin-top: 2px;
  }
}

.page-product__card-notice {
  margin: 0 -1rem 1rem;
  padding: 1.5rem;
  font-weight: 400;
  background-color: #7f7463;
  
  &-title {
    margin-bottom: 0.25rem;
    font-weight: 600;
    font-size: 1.5em;
    line-height: 1;
  }
}

.page-product__card-features {
  margin: 0 -1rem;
  
  &-name {
    width: 40%;
  }
  
  &-value {
    width: 60%;
  }
}

.page-product__card-features-control {
  width: 100%;
  padding: 1rem;
  background-color: #f6f6f6;
  border-top: 1px solid $color-bg-gray;
  
  .icon {
    margin-left: auto;
    margin-right: -0.25rem;
    padding: 0.25rem;
    transform: rotate(-90deg);
  }
  
  &.is-active .icon {
    transform: rotate(90deg);
  }
}


.page-product__card-features-content {
  max-height: 100rem;
  overflow: hidden;
  
  &.is-active {
    max-height: 0;
    opacity: 0;
  }
}


.page-product__description-body {
  border-top: 1px solid $color-bg-gray;
  
  .page-product__description-control {
    display: block;
    width: 100%;
    padding: 1rem;
    background-color: #f6f6f6;
    border-bottom: 1px solid $color-bg-gray;
    
    .icon {
      margin-left: auto;
      margin-right: -0.25rem;
      padding: 0.25rem;
      transform: rotate(90deg);
    }
    
    &.is-active .icon {
      transform: rotate(-90deg);
    }
  }
}

.page-product__description-item {
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  
  &.is-opened {
    max-height: 100rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid #e1e1e1;
    opacity: 1;
  }
  
  .user-content {
    font-size: 13px;
  }
}

.page-product__card-share {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
}

.product-variants {
  margin-top: 2.5rem;
  
  &__title {
    font-size: 1.5em;
  }
  
  &__footer {
    margin-top: -1px;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    border-top: 1px solid #e7e7e7;
    border-bottom: 1px solid #e7e7e7;
    background-color: #f7f7f7;
  }
  
  &__item {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    border-bottom: 1px solid #e7e7e7;
  }
  
  &__name {
    margin-bottom: 0.5rem;
    font-size: 0.75em;
  }
  
  &__detail {
    font-size: 0.75em;
    
    &-title {
      display: inline-block;
      vertical-align: top;
      width: 4.75em;
      margin-right: 0.25em;
    }
  }
  
  &__image {
    cursor: pointer;
  }
  
  &__price {
    font-size: 0.875em;
    text-align: right;
  }
}

.related-product {
  margin-top: 2.5rem;
  
  &__title {
    margin-bottom: 1rem;
    font-size: 1.5em;
  }
}

@media (min-width: 768px) {
 
  .product-variants__price {
    font-size: 1em;
  }
  
  .product-variants__name,
  .product-variants__detail {
    font-size: 0.875em;
  }
  
}

@media (max-width: 1023px) {
 
  .page-product__image {
    padding: 0;
    
    .slick-dots {
      position: absolute;
      bottom: 1rem;
      left: 1rem;
      right: 1rem;
    }
  }

  .product-variants__image {
    padding-right: 0;
  }

  .page-product__card-features .table {
    background-color: #fff;
  }

  .page-product__card-features .table,
  .page-product__card-features .table th,
  .page-product__card-features .table td {
    border-bottom: 0;
  }

  .page-product__description {
    padding: 0;
    
    &-item {
      padding: 0 1rem;
    }
  }
  
}


@media (min-width: 1024px) {
  
  .page-product {
    padding-top: 2.5rem;
    padding-bottom: 0;
    
    &__title {
      margin-bottom: 2.5rem;
      line-height: 1;
      background: none;
    }
    
    &__image {
      margin-bottom: 2rem;
      
      .product-sticker {
        left: 10px;
      }
    }
    
    &__card {
      display: flex;
      flex-direction: column;
      float: right;
      
      & > * {
        order: 1;
      }
    }
  }

  .page-product__card-info {
    display: flex;
    justify-content: center;
    order: 0;
    margin-top: 0;
    margin-left: 0;
    margin-right: 0;
    padding-bottom: 1rem;
    border-bottom: 1px solid $color-bg-gray;
    text-align: center;
    
    &-item {
      flex-grow: 1;
      margin: 0;
      border-right: 1px solid $color-bg-gray;
      
      &:last-child {
        border-right: 0;
      }
    }
    
    &-title {
      margin: 0 0 0.25rem;
      display: block;
    }

  }

  .page-product__card-notice {
    margin: 0 -2px 0.75rem;
  }

  .page-product__card-price {
    margin: 0 0 1.5rem;
    text-align: center;
    
    .price__item--status {
      display: block;
      margin: 0 0 0.5rem;
    }
  }

  .page-product__card-features {
    margin: 0;
    max-height: none;
    
    .is-active {
      max-height: none;
      opacity: 1;
    }
  }

  .page-product__card-share {
    position: relative;
    left: auto;
    bottom: auto;
    margin-top: 1rem;
    
    .share__list {
      display: block;
      margin: 0;
    }
  }

  .page-product__description {
    margin: 0;
    border: 0;
    
    &-control {
      padding: 0.5rem 1.25rem;
      margin-right: 1.5rem;
      border-bottom: 2px solid rgba(0, 0, 0, 0);
      
      &.is-active {
        color: #b1a082;
        border-color: #b6a68a;
      }
    }
  }

  .page-product__description-item.is-opened {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .product-variants {
    margin-top: 4rem;
    
    &__title {
      font-size: 2em;
      margin-bottom: 1rem;
    }
    
    &__footer {
      padding-top: 1rem;
      padding-bottom: 1rem;
      background: none;
      
      &-col {
        margin-left: auto;
      }
    }
    
    &__item {
      padding-top: 2rem;
      padding-bottom: 2rem;
    }
    
    &__inner {
      display: flex;
      flex-wrap: wrap;
    }
    
    &__image, &__info {
      order: 1;
    }
    
    &__name {
      margin-bottom: 0.75rem;
    }
    
    &__price {
      margin-top: 0.5rem;
      font-size: 1.5em;
      text-align: left;
    }
  }

  .related-product {
    margin-top: 4rem;
    
    &__title {
      margin-bottom: 2rem;
      font-size: 2em;
    }
  }

}

@media (min-width: 1024px) and (max-width: 1279px) {
  
  .page-product__card-info--sku {
    width: 100%;
    margin-bottom: 1rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid $color-bg-gray;
    border-right: 0;
    
    .page-product__card-info-title,
    .page-product__card-info-value {
      display: inline-block;
    }
  }
  
} 
  
@media (min-width: 1280px) {
  
  .page-product__image .product-sticker {
    left: 1rem;
  }
  
  .page-product__card-info {
    display: flex;
    justify-content: center;
    
    &-item {
      flex-grow: 1;
      width: auto;
      padding-left: 1.5rem;
      padding-right: 1.5rem;
    }
  }
  
  .page-product__card-notice {
    margin-bottom: 1.25rem;
  }
  
  .product-variants__name,
  .product-variants__detail {
    font-size: 1em;
  }
}