.mCustomScrollbar,
.mCustomScrollbar.mCS_no_scrollbar {
  touch-action: auto;
}

.mCustomScrollBox {
  position: relative;
  overflow: hidden;
  height: 100%;
  max-width: 100%;
}

.mCustomScrollbar:focus,
.mCustomScrollBox:focus {
  outline: none;
}

.mCSB_container {
  overflow: hidden;
  width: auto;
  height: auto;
  min-height: 100%;
  transition: padding 0.3s ease;
}

.mCSB_container.mCS_no_scrollbar_y {
  padding-right: 0;
}

.mCSB_scrollTools {
  position: absolute;
  width: 6px;
  height: auto;
  left: auto;
  top: 0;
  right: 0;
  bottom: 0;
}

.mCSB_scrollTools .mCSB_draggerContainer {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: auto;
}

.mCSB_scrollTools a + .mCSB_draggerContainer {
  margin: 20px 0;
}

.mCSB_scrollTools .mCSB_draggerRail {
  width: 100%;
  height: 100%;
  background-color: rgba(175, 175, 175, 0.25);
}

.mCSB_scrollTools .mCSB_dragger {
  cursor: pointer;
  width: 100%;
  height: 30px;
  z-index: 1;
}

.mCSB_scrollTools .mCSB_dragger_bar {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  background: #b1a082;
}

.mCS-dark .mCSB_dragger_bar {
  background-color: #414141;
}