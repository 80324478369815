@import "base-vars";

/*
 * ОБЩЕЕ
 * ЗАПРОС ЦЕНЫ
 * СТРАНИЦА КОРЗИНЫ
 * СТРАНИЦА ОФОРМЛЕННОГО ЗАКАЗА
 * СТРАНИЦА ОТЗЫВОВ
 * СТРАНИЦА БРЕНДА ТЕКСТИЛЯ
 * СТРАНИЦА ОШИБОК
 * БАННЕР КОНСЬЕРЖА
*/

/* BEGIN - ОБЩЕЕ */

.main {
  padding-bottom: 2rem;
  flex-grow: 1;
}

/* END - ОБЩЕЕ */


/* BEGIN - ЗАПРОС ЦЕНЫ */

.notepad__products-remove {
  padding: 0.25rem;
  cursor: pointer;
}

.notepad__notice-empty {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1;
  display: none;
  transform: translate3d(-50%, -50%, 0);
}

.notepad__products {
  padding: 2rem 1rem;
  border-bottom: 1px solid #f6f5f1;
}

.notepad__products-item {
  margin-bottom: 2rem;
}

.notepad__products-item:last-child {
  margin-bottom: 0;
}

.notepad__products-remove {
  top: 0;
  left: 0.5rem;
}

.notepad__products-name {
  margin-bottom: 0.5rem;
  font-size: 0.75em;
  line-height: 1.2;
}

.notepad__products-detail {
  font-size: 0.75em;
}

.notepad__form {
  padding: 2rem 1rem;
}

/* END - ЗАПРОС ЦЕНЫ */

/* BEGIN - СТРАНИЦА КОРЗИНЫ */

.page-cart__table {
  margin: 0 -1rem 2rem;
  padding-top: 1rem;
  border-top: 1px solid #e7e7e7;
}

.page-cart__table .price {
  color: #99835c;
}

.page-cart__table-row {
  position: relative;
  margin-bottom: 1rem;
  padding-left: 2rem;
  padding-right: 0.5rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid #e7e7e7;
  flex-wrap: wrap;
}

.page-cart__table-row--title {
  color: #8e8e8e;
}

.page-cart__table-col {
  display: inline-block;
  vertical-align: top;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.page-cart__table-col--image {
  width: 20%;
}

.page-cart__table-col--name {
  display: block;
  width: 80%;
  max-width: 18rem;
  font-size: 0.75em;
}

.page-cart__table-detail {
  width: 80%;
  max-width: 18rem;
  margin-left: auto;
  overflow: hidden;
}

.page-cart__table-col--price {
  float: right;
  padding-top: 0.75rem;
  text-align: right;
}

.page-cart__table-col--amount {
  float: left;
  margin-left: -4px;
}

.page-cart__table-col--remove {
  position: absolute;
  top: 0;
  left: 0;
}

.page-cart__table-col--remove .icon {
  padding: 0.25rem;
}

.page-cart__table-total {
  padding: 0 1rem;
  overflow: hidden;
}

.page-cart__table-total-name {
  display: inline-block;
  vertical-align: top;
  width: 50%;
  font-size: 1.125em;
  color: #8e8e8e;
}

.page-cart__table-total-price {
  display: inline-block;
  vertical-align: top;
  width: 50%;
  text-align: right;
}

.page-cart__delivery {
  margin-bottom: 2rem;
}

.page-cart__payment {
  margin-bottom: 2.5rem;
}

.page-cart__form-content {
  max-width: 28rem;
  margin: 1.5rem auto 0;
}

.page-cart__form-price-title {
  font-size: 1.125em;
}

.page-cart__form .button {
  max-width: 21.25rem;
  margin: 0 auto;
}

.bill-list {
  margin: 0 -1rem;
  border-bottom: 1px solid #e7e7e7;
}

.bill-list__item {
  position: relative;
  padding: 1rem;
  border-top: 1px solid #e7e7e7;
}

.bill-list__item:last-child {
  margin-bottom: 0;
}

.bill-list__name {
  margin-right: 2rem;
}

.bill-list__icon {
  position: absolute;
  top: 1rem;
  right: 1rem;
  z-index: 1;
  margin-top: -2px;
  color: #b1a082;
  border-radius: 50%;
  cursor: pointer;
}

.bill-list__icon.is-active {
  color: #fff;
  background-color: #b1a082;
}

.bill-list__description {
  margin: 0 -1rem;
  max-height: 0;
  opacity: 0;
  overflow: hidden;
}

.bill-list__description.is-active {
  margin-top: 1rem;
  margin-bottom: -1rem;
  max-height: 100%;
  opacity: 1;
}

.bill-list__description-inner {
  padding: 1rem;
  background-color: #f4f4f4;
}

/* END - СТРАНИЦА КОРЗИНЫ */

/* BEGIN - СТРАНИЦА ОФОРМЛЕННОГО ЗАКАЗА */

.page-order__notice,
.page-order__section {
  margin-bottom: 1.5rem;
}

.page-order__section--contact {
  margin-bottom: 1.5rem;
  padding-bottom: 1.5rem;
  border-bottom: 1px solid #e1e1e1;
}

.page-order__section-frame {
  padding: 1.5rem 1rem;
  outline: 0.25rem solid #f0f0f0;
}

.order-price {
  margin-top: 1rem;
}

.checkout_form {
  margin-top: 1rem;
}

.page-order__product-item {
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  border-bottom: 1px solid #e1e1e1;
}

.page-order__product-total .page-order__product-item {
  margin-top: 0.5rem;
  margin-bottom: 0;
  padding-bottom: 0;
  border: 0;
}

.checkout_form input[type="text"] {
  margin-bottom: 1.5rem;
}

.change-payment__item {
  margin-bottom: 1.5rem;
}

/* END - СТРАНИЦА ОФОРМЛЕННОГО ЗАКАЗА */

/* BEGIN - СТРАНИЦА ОТЗЫВОВ */

.page-reviews__list {
  overflow: hidden;
  white-space: nowrap;
}

.page-reviews__list.slick-initialized {
  margin-left: -1rem;
  margin-right: -1rem;
  overflow: visible;
  white-space: normal;
}

.page-reviews__list .slick-list {
  padding-right: 3rem;
}

.page-reviews__item {
  display: inline-block;
  vertical-align: top;
  float: none;
  padding-bottom: 1px;
  white-space: normal;
}

.page-reviews__list.slick-initialized .page-reviews__item {
  display: block;
  float: left;
  margin: 0 1rem;
}

.page-reviews__image {
  margin-bottom: 0.75rem;
}

.page-reviews__title {
  font-size: 0.875em;
}

.page-reviews__subtitle {
  margin-top: 0.5rem;
  font-size: 0.75em;
  line-height: 1;
}

.page-reviews__description {
  margin-top: 1.5rem;
  padding-top: 1rem;
  border-top: 1px solid #e7e7e7;
}

.page-reviews__readmore {
  margin-top: 2rem;
}

.page-reviews__readmore .readmore-link {
  min-width: 14rem;
}


/* END - СТРАНИЦА ОТЗЫВОВ */


/* BEGIN - СТРАНИЦА БРЕНДА ТЕКСТИЛЯ */

.textile-brand__nav {
  margin-bottom: 2.5rem;
  font-size: 1.25em;
  border-bottom: 1px solid #f1eee9;
}

.textile-brand__nav-item {
  display: inline-block;
  vertical-align: top;
  margin: 0 1rem;
}

.textile-brand__nav-link {
  position: relative;
  display: block;
  padding: 0 0.25rem 1rem;
  line-height: 1;
  border-bottom: 2px solid rgba(0, 0, 0, 0);
}

.textile-brand__nav-link:hover,
.textile-brand__nav-link.is-active {
  color: #b1a082;
}

.textile-brand__nav-link.is-active {
  border-color: #b1a082;
}

.textile-brand__intro {
  margin-bottom: 2.5rem;
  padding-bottom: 4rem;
  border-bottom: 1px solid #f1eee9;
}

.textile-brand__intro-description {
  height: 12rem;
  margin: 0 4.5rem;
  overflow: hidden;
}

.textile-brand__intro-description .user-content {
  height: 100%;
}

.textile-brand__intro-description .mCSB_container {
  padding: 0 3rem;
}

.textile-brand__intro-description .mCSB_container.mCS_no_scrollbar_y {
  padding-right: 3rem;
}

.textile-brand__section {
  margin-bottom: 2rem;
}

.textile-brand__section:last-child {
  margin-bottom: 0;
}

.textile-brand__gallery {
  margin-bottom: 1rem;
}

.textile-brand__section-title {
  margin-bottom: 0.5rem;
  font-size: 1.125em;
}

.textile-brand__section-description {
  max-height: 3em;
  overflow: hidden;
}

.textile-brand__section-description.is-expanded {
  max-height: none;
}

.textile-brand__section-readmore {
  margin-top: 0.5rem;
}

.textile-brand__section-readmore .icon {
  padding: 0.25rem;
  transform: rotate(90deg);
}

.textile-brand__section-readmore is-active .icon {
  transform: rotate(-90deg);
}

.textile-brand__other-list {
  margin-top: 1rem;
}

.textile-brand__other-control {
  opacity: 0.3;
}

.textile-brand__other-control:hover {
  opacity: 1;
}

.textile-brand__other-control .icon {
  width: 3rem;
  height: 3rem;
}

.textile-brand__other-control--prev .icon {
  transform: rotate(180deg);
}

.textile-brand__other img {
  max-height: 3rem;
}

/* END - СТРАНИЦА БРЕНДА ТЕКСТИЛЯ */


/* BEGIN - СТРАНИЦА ОШИБОК */

.page-error {
  position: relative;
  margin-bottom: -2rem;
  background-color: #333;
}

.page-error::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  display: block;
  width: 100%;
  height: 5rem;
  background-color: #fff;
}

.page-error__container {
  position: relative;
  height: 35rem;
  min-height: 27rem;
  max-height: 75vh;
  z-index: 1;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.page-error__content {
  color: #d4d4d4;
}

.page-error__title {
  font-family: 'Minion Pro', serif;
  font-weight: 400;
  font-style: italic;
  color: #bcaf97;
}

.page-error__link {
  margin-top: 1rem;
  color: #fff;
}

.page-error__bg {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  background-position: center top;
  background-size: cover;
  background-repeat: no-repeat;
}

.page-error__bg::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: block;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0.68) 100%);
}

/* END - СТРАНИЦА ОШИБОК */

/* BEGIN - БАННЕР КОНСЬЕРЖА */

.concierge-banner {
  background-color: #2C2D32;
  overflow: hidden;
  font-weight: 400;
}

.concierge-banner__image::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.75) 0%, rgba(0, 0, 0, 0.25) 100%);
}

.concierge-banner__content {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.concierge-banner__title {
  margin-bottom: 0.5rem;
  font-size: 1.125em;
}

.concierge-banner__description {
  margin-bottom: 1rem;
  font-size: 0.875em;
  color: #d4d4d4;
}

.concierge-banner__readmore {
  margin-top: 1.5rem;
  font-size: 1rem;
}

/* END - БАННЕР КОНСЬЕРЖА */