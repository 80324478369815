/*фильтр товаров в категории*/

@import "base-vars";

.filter__title {
  margin-right: 0.5rem;
  height: 2rem;
}

.filter__form {
  margin-top: -0.5rem;
  
  &-section {
    position: relative;
    display: inline-block;
    vertical-align: top;
    margin-right: 0.5rem;
    margin-top: 0.5rem;
  }
  
  &-control {
    display: block;
    padding: 0.25rem 1rem;
    line-height: 1.5rem;
    border: 1px solid $color-black;
    border-radius: 4px;
    transition: $base-transition;
    cursor: pointer;

    &:hover, &.is-active {
      color: $color-main;
      background-color: rgba(0,0,0,0);
      border-color: $color-main;
      box-shadow: 0 0 0 1px $color-main;
    }
    
  }
  
  &-content {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1;
    display: none;
    margin-top: 1rem;
    min-width: 100%;
    white-space: nowrap;
    color: #666;
    border-top: 2px solid $color-main;
    background-color: $color-bg-gray;
    
    &.is-active {
      display: block;
    }
  }
  
  &-list {
    max-height: 20rem;
    padding: 0.75rem 1.5rem 0 0.75rem;
    overflow-y: auto;
  }
  
  &-item {
    margin-bottom: 0.75rem;
  }
  
  .checkbox-label:hover,
  .checkbox-label.is-checked,
  input.hidden:checked+.checkbox-label {
    color: $color-black;
  }
  
  .checkbox-label::before {
    background-color: rgba(0,0,0,0);
    border-color: #b6b6b6;
    border-radius: 0;
  }
  
  .checkbox-label.is-checked::before, 
  input.hidden:checked+.checkbox-label::before {
    border-color: #666;
    background-color: rgba(0,0,0,0);
    background-image: url(../images/checkbox-dark.svg);
  }
}

@media(min-width: 1024px) {
  
  .filter {
    justify-content: center;
    
    &__title {
      margin-right: 1.75rem; 
      text-transform: uppercase;
      font-family: $font-serif;
      font-size: 1.2875em;
      
      .icon-text {
        margin-top: 2px;
      }
    }
  }
  
}