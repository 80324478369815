/*Страницы проектов*/

@import "base-vars";

.project-nav {
  padding-top: 1.5rem;
  padding-bottom: 2rem;
  border-top: 1px solid #e7e7e7;
  border-bottom: 1px solid #e7e7e7;
}

.project-group {
  margin-top: 2.5rem;
  padding-top: 2.5rem;
  padding-bottom: 1px;
  border-top: 1px solid #e7e7e7;
  
  &:first-child {
    margin-top: 0;
    padding-top: 0;
    border-top: 0;
  }
  
  &__title {
    margin-bottom: 2.5rem;
    font-size: 1.25em;
  }
}

.page-project {
  position: relative;
  padding-bottom: 1.5rem;
  border-bottom: 1px solid #e7e7e7;
  display: flex;
  flex-direction: column;
}

.page-project > * {
  order: 1;
}

.page-project .page-project__image {
  order: 0;
  
  .slick-dots li:hover button::before,
  .slick-dots li.slick-active button::before {
    background-color: $color-black;
    border-color: $color-black;
  }
}

.page-project__title {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  line-height: 1.25;
  background-color: $color-black;
}

.page-project__info {
  margin-top: 2rem;
}

.page-project__group {
  margin-bottom: 1rem;
}

.page-project__features,
.page-project__description {
  margin: 0 -1rem 1.5rem;
  padding: 1.5rem 1rem 0;
  border-top: 1px solid #e7e7e7;
}

.page-project__features-item {
  margin-bottom: 1rem;
  
  &:last-child {
    margin-bottom: 0;
  }
}

.page-project__features-name {
  min-width: 35%;
  padding-right: 2rem;
}

.related-project {
  margin-top: 2.5rem;
  
  &__title {
    margin-bottom: 1rem;
    font-size: 1.5em;
  }
}

@media (max-width: 1023px) {
  
  .project-group {
    margin-left: -1rem;
    margin-right: -1rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
  
  .page-project {
    &__share {
      margin-left: -1rem;
      margin-right: -1rem;
      padding-left: 1rem;
      padding-right: 1rem;
    }
    
    &__image {
      padding: 0;
      
      .slick-dots {
        position: absolute;
        left: 1rem;
        right: 1rem;
        bottom: 1rem;
      }
    }
  }

}

@media (min-width: 1024px) {
  
  .project-nav {
    padding-top: 0;
    padding-bottom: 0;
    border: 0;
    text-transform: uppercase;
    
    .tabs__header-item {
      width: 50%;
    }
    
    .tabs__header-link {
      padding-left: 3rem;
      padding-right: 3rem;
    }
  }

  .project-group__title {
    font-size: 1.5em;
  }

  .page-project {
    display: block;
    padding-bottom: 0;
    border-bottom: 0;
    
    &__title {
      padding-top: 2rem;
      padding-bottom: 2rem;
      line-height: 1;
      background: none;
    }
    
    &__info {
      margin-top: 0;
    }  
    
    &__image {
      float: right;
    }
    
    &__features, &__description {
      margin-left: 0;
      margin-right: 0;
      padding-left: 0;
      padding-right: 0;
    }
  }

  .page-project__features-name {
    min-width: 25%;
  }
  
  .related-project {
    margin-top: 4rem;
    
    &__title {
      margin-bottom: 2rem;
      font-size: 2em;
    }
  }
  
}

@media (min-width: 1280px) {
  
  .project-nav .tabs__header-link {
    padding-left: 20%;
    padding-right: 20%;
  }

  .project-group {
    margin-top: 4rem;
    padding-top: 4rem;
    
    &__title {
      font-size: 2em;
      margin-bottom: 3.5rem;
    }
  }
  
}