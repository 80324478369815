@import "base-vars";

/*Шапка сайта*/

body {
  padding-top: 3.25rem;
  overflow-x: hidden;
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  background-color: $color-black;
  
  &::before {
    content: '';
    position: absolute;
    top: 3.25rem;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ebebeb; 
  }
  
  &__top {
    position: fixed;
    top: 0;
    right: 100%;
    z-index: 3;
    width: 100%;
    height: 3.25rem;
    padding: 0 0.5rem;
    font-size: 0.875em;
    line-height: 1.2;
    text-align: center;
    color: $color-main;
    background-color: $color-black;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: all 0.3s ease;
    
    &.is-visible {
      right: 0;
    }
  }

  &__bottom {
    position: fixed;
    bottom: 0;
    right: 100%;
    z-index: 3;
    width: 100%;
    padding: 1rem;
    line-height: 1;
    text-align: center;
    text-transform: none;
    background-color: $color-main;
    transition: all 0.3s ease;
    
    &.is-visible {
      right: 0;
    }
    
    & .readmore-link {
      font-size: 1em;
      color: #fff;
    }
  }
}

.header__logo {
  line-height: 1;
  
  &-image {
    width: 100%;
    padding-left: 0.25rem;
    margin-top: -2px;
  }
}

.header__informer--notepad {
  margin-right: 0.5rem;
}

.header__informer-inner {
  color: $color-main;
  cursor: pointer;
  
  &:hover {
    color: #ddd;
    
    & .header__informer-counter {
      color: #fff;
    }
  }
  
  svg {
    margin-top: -2px;
  }
}

.header__informer-counter {
  position: absolute;
  bottom: 0.25rem;
  right: -0.25rem;
  z-index: 1;
  width: 1.25rem;
  height: 1.25rem;
  font: 700 11px/1.25rem $font-sans-serif;
  text-align: center;
  background-color: $color-main;
  border-radius: 50%;
}

.header__currency-button {
  display: inline-block;
  vertical-align: top;
  padding: 0.25rem 1rem;
  font-size: 1.125em;
  line-height: 1;
  color: #999;
  cursor: pointer;
  
  &:hover, &.is-active {
    color: #ddd;
  }
  
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    right: 0;
    display: block;
    width: 1px;
    height: 1rem;
    margin-top: -1px;
    background-color: #4c4c4c;
    transform: translateY(-50%);
  }
  
  &:first-child {
    padding-left: 0.5rem;
  }
  
  &:last-child {
    padding-right: 0.5rem;
    &::after {
      display: none;
    }
  }
}

.header__search {
  padding: 1.25rem 1rem 1rem;
  
  &-input {
    display: block;
    width: 100%;
    height: 2.75rem;
    padding: 0.5rem 3rem 0.5rem 1rem;
    font: 400 0.875em/1.75rem $font-sans-serif;
    color: $color-text-gray;
    background-color: #d8d8d8;
    flex-grow: 1;

    &::placeholder {
      color: $color-text-gray;
      opacity: 1;
    }

    &:focus::placeholder {
      opacity: 0;
    }
  }
  
  &-button {
    top: 2px;
    right: 0;
  }
}

.header__category-control {
  width: 100%;
  padding: 0.5rem 1rem;
  
  &-name {
    padding-top: 3px;
  }
  
  &-arrow {
    width: 1rem;
    height: 1rem;
    margin-left: auto;
    margin-right: 0.75rem;
  }
  
  &.is-active &-arrow {
    margin-top: -2px;
    transform: rotate(-90deg);
  }
}

.header__category-content {
  background-color: #d8d8d8;
  color: $color-black;
  overflow: hidden;
  transition: $base-transition;
  
  &.level-2, &.level-3 {
    position: fixed;
    top: 0;
    right: 100%;
    width: 100%;
    height: 100%;
    
    &.is-active {
      right: 0;
    }
  }
  
  &.level-2 {
    z-index: 2;
  }
  
  &.level-3 {
    z-index: 3;
  }
}

.header__category-list {
  height: 100%;
  overflow-y: auto;
}

.header__category-item:not(.level-1) {
  &:first-child {
    margin-top: 1rem;
  }
  
  &:last-child {
    margin-bottom: 1rem;
  }
}

.header__category-name {
  display: flex;
  align-items: center;
  padding: 0 1rem;
  transition: $base-transition;
  
  &:hover, &.is-active {
    background-color: rgba(0,0,0,0.1);
  }
}

.header__category-link {
  padding: 0.5rem 0;
  padding-left: 2rem;
  flex-grow: 1;
}

.header__category-button {
  margin-left: auto;
  padding: 0.75rem;
  transition: $base-transition;
}

.header__menu-item {
  padding: 0 1rem;
}

.header__menu-link {
  padding: 0.25rem 0;
  padding-left: 2rem;
  
  &:hover, &.is-active {
    color: $color-main;
  }
}

@media (max-width: 1023px) {
  
  .header {
    height: 3.25rem;
    padding-top: 3.25rem;
    overflow: hidden;
    transition-delay: 0.3s;
    
    &.is-active {
      height: 100%;
      padding-bottom: 3.25rem;
      transition-delay: 0s;
    }
    
    &__container {
      padding: 0;
      height: 100%;
      overflow-y: auto;
    }
    
    &__logo {
      position: fixed;
      top: 0;
      left: 1rem;
      height: 3.25rem;
      max-width: 55%;
    }
    
    &__info {
      position: fixed;
      top: 0;
      right: 1rem;
      height: 3.25rem;
    }
    
    &__currency {
      position: absolute;
      top: 50%;
      right: -10rem;
      transform: translateY(-50%);
    }

    &__nav, &__menu {
      opacity: 0;
      right: 100vw;
    }
    
  }
  
  .header-open {
    overflow: hidden;
    
    & .header__logo-image {
      opacity: 0;
      visibility: hidden;
    }
    
    & .header__currency {
      right: 0;
      transition-delay: 0.6s;
    }
    
    & .header__informer {
      transform: translateX(10rem);
    }
    
    & .header__nav, & .header__menu {
      right: 0;
      opacity: 1;
    }
    
    & .header__nav {
      transition-delay: 0.3s;
    }
    
    & .header__menu {
      transition-delay: 0.3s;
    }
    
    & .header__contact {
      bottom: 0;
      transition-delay: 0.6s;
    }
  }
  
  .header__category-content {
    
    &.level-1 {
      opacity: 0;
      max-height: 0;

      &.is-active {
        opacity: 1;
        padding: 0.5rem 0;
        margin-top: 0.25rem;
        margin-bottom: 1rem;
        max-height: 100rem;
        transition: max-height 0.2s ease, opacity 0.3s ease 0.2s;
      }
    }
    
    &.level-2, &.level-3 {
      padding-top: 3.25rem;
      padding-bottom: 3rem;
    }
  }
  
  .header__menu {
    color: $color-text-gray;

    &-section {
      padding-bottom: 1rem;

      &--first {
        font-size: 1.125em;
        font-family: $font-serif;
      }

      &--second {
        padding-top: 1rem;
        font-size: 0.875em;
        line-height: 1.5rem;
        letter-spacing: 0.075em;
        border-top: 1px solid #dcdcdc;

        .header__menu-link {
          padding-top: 0.5rem;
          padding-bottom: 0.5rem;
        }
      }
    }
  }
  
  .header__contact {
    position: fixed;
    left: 0;
    right: 0;
    bottom: -10rem;
    padding: 1rem;
    font: 400 1.375em/1.25rem $font-serif;
    background-color: $color-main-dark;
  }
  
}

@media (min-width: 1024px) {
  
  body {
    padding-top: 6.5rem;
  }
  
  .header {
    height: 6.5rem;

    &::before {
      top: auto;
      bottom: 0;
      height: 3.25rem;
      background-color: #434343;
    } 
    
    &__container {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
    }
    
    &__nav {
      order: 1;
    }
    
    &__menu {
      display: flex;
      align-items: center;
      width: 100%;
      
      &-section {
        font-family: $font-serif;
      }
      
      &-item {
        padding: 0;
      }
      
      &-section--first {
        color: #fff;
        font-size: 0.875em;
        white-space: nowrap;
        opacity: 0.7;
      }
      
      &-section--first &-item {
        display: inline-block;
        vertical-align: top;
        margin-right: 2rem;
        
        &:last-child {
          margin: 0;
        }
      }
      
      &-section--first &-link {
        padding: 0;
        line-height: 3.25rem;
        
        &.is-active {
          color: $color-main;
        }
      }
    }
    
    &__contact {
      margin-left: auto;
      
      &-phone {
        margin-bottom: 0.25rem;
        font-weight: 600;
        font-size: 0.875em;
      }
    }
    
    &__logo {
      order: 2;
    }
    
    &__info {
      order: 3;
      height: 3.25rem;
      margin-left: auto;
    }
    
    &__currency {
      margin-top: 2px;
      margin-left: auto;
      line-height: 1;
    }
  }
  
  .header__category-content.is-large {
    
    .header__category-item.level-2 {
      display: inline-block;
      vertical-align: top;
      width: 33.333%;
      padding: 0 1rem 1.5rem;
      
      .header__category-content {
        position: static;
        width: auto;
        opacity: 1;
        visibility: visible;
      }
      
      .header__category-list {
        overflow: visible;
      }
      
      .header__category-item {
        margin: 0;
      }
      
      .header__category-name {
        padding: 0;
        
        &:hover, &.is-active {
          background: none;
        }
      }
    }
    
    .header__category-link {
      padding-left: 0;
      transition: $base-transition;
      
      &.level-2 {
        position: relative;
        margin-bottom: 0.5rem;
        font: 600 1em/1 $font-serif;
        text-transform: uppercase;
        
        &::after {
          content: '';
          position: absolute;
          top: 100%;
          left: 0;
          display: block;
          width: 2.5rem;
          height: 1px;
          background-color: $color-main;
        }
      }
      
      &.level-3 {
        padding-top: 2px;
        padding-bottom: 2px;
        font-size: 0.8125em;
        color: $color-text-gray;
        text-transform: none;
      }
      
      &:hover {
        color: $color-main;
      }
    }
    
    .header__category-button {
      display: none;
    }
    
    .header__category-readmore {
      margin-top: 0.25rem;
      padding: 0.25rem 1.75rem 0.25rem 0;
      font: 600 13px/1 $font-sans-serif;
      color: #bc9a5e;
      text-transform: none;
      
      &::after {
        width: 25px;
        margin-top: 1px;
        background-image: url(../images/readmore-arrow-category.svg);
      }
    }
  }
  
}

@media (min-width: 1024px) and (max-width: 1279px) {
  
  .header__menu-section--second {
    position: absolute;
    top: 100%;
    left: 35%;
    font-size: 1.125em;
    
    .header__menu-control {
      height: 3.25rem;
      color: #fff;
      
      & .icon-text {
        margin-top: 2px;
      }
    }
    
    .header__menu-list {
      position: absolute;
      top: 100%;
      left: 0;
      width: 18.5rem;
      max-height: 0;
      background-color: $color-bg-gray;
      opacity: 0;
      overflow: hidden;
      transition: $base-transition;
      
      &.is-active {
        padding: 1rem 0;
        max-height: 20rem;
        opacity: 1;
      }
    }
  }
  
  .header__nav-control {
    width: 3.25rem;
    height: 3.25rem;
    margin-left: -1rem;
    margin-right: 1rem;
    
    &.is-active {
      color: #fff;
    }
  }
  
  .header__nav-content {
    position: fixed;
    top: 6.5rem;
    bottom: 0;
    left: -32vw;
    width: 32vw;
    background-color: $color-bg-gray;
    overflow-y: auto;
    
    &.is-active {
      left: 0;
    }
  }
  
  .header__nav-contact {
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    padding: 1rem;
    background-color: #7f7462;
    
    &-address {
      margin-bottom: 0.75rem;
      font-size: 0.875em;
      line-height: 1.25rem;
      color: #cecece;
    }
    
    &-phone {
      line-height: 1.25rem;
    }
  }
  
  .header__search {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
    padding: 2rem 2rem 1rem;
  }
  
  .header__category {
    padding-top: 5.75rem;
    padding-bottom: 6.5rem;
    height: 100%;
    
    &-item:not(.level-1):first-child {
      margin-top: 2rem;
    }
    
    &-item:not(.level-1):last-child {
      margin-bottom: 0;
    }
    
    &-link {
      padding-left: 1rem;
      font-size: 0.875em;
    }

  }
  
  .header__category-content {
    background-color: $color-bg-gray;
    
    &.level-1 {
      position: relative;
      z-index: 1;
      height: 100%;
      background-color: $color-bg-gray;
    }
    
    &.level-2, &.level-3 {
      top: 6.5rem;
      left: -32vw;
      right: auto;
      bottom: 0;
      width: 32vw;
      height: auto;
      opacity: 0;
      visibility: hidden;
      transition-property: opacity;
      
      &.is-active {
        opacity: 1;
        visibility: visible;
        transition-delay: 0.2s;
      } 
    }
    
    &.level-2.is-active {
      left: 32vw;
    } 
    
    &.level-3.is-active {
      left: 64vw;
    }
    
    &.is-large {
      left: -64vw;
      padding-left: 0.5rem;
      
      &.is-active {
        width: 64vw;
      }
      
      .header__category-item.level-2 {
        &:nth-child(1), &:nth-child(2), &:nth-child(3) {
          margin-top: 1.5rem;
        }
      }
      
    }
    
  }

}

@media (min-width: 1280px) {
  
  body {
    padding-top: 6.75rem;
  }
  
  .header {
    height: 6.75rem;
    
    &::before {
      height: 3.5rem;
    }
    
    &__container {
      padding: 0;
    }
  }

  .header__menu {
    width: 64.5rem;
  }
  
  .header__info {
    order: 1;
  }
  
  .header__currency-button {
    font-size: 0.75em;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  
  .header__nav {
    order: 3;
    margin-left: auto;
    padding-right: 2rem;
  }
  
  .header__search {
    position: absolute;
    z-index: 1;
    right: 0;
    bottom: 0.5rem;
    padding: 0;
    
    &-control {
      right: -0.5rem;
      bottom: -2px;
      transition: $base-transition;
      
      &.is-active {
        opacity: 0;
      }
    }
    
    &-form {
      width: 0;
      overflow: hidden;
      &.is-active {
        width: 15.5rem;
      }
    }
    
    &-input {
      height: 2.5rem;
      padding-left: 3rem;
      padding-right: 1rem;
      line-height: 1.5rem;
      background-color: #6f6f6f;
      color: rgba(255,255,255,0.5);
      
      &::placeholder {
        color: rgba(255,255,255,0.5);
      }
    }
    
    &-button {
      top: 0;
      left: 0;
      right: auto;
      color: rgba(255,255,255,0.5);
    }
  }
  
  .header__category-content {
    overflow: visible;
    
    &.level-1 {
      position: static;
      background: none;
    }
    
    &.level-2 {
      position: absolute;
      top: 100%;
      left: 0;
      right: auto;
      min-width: 13rem;
      width: auto;
      height: 0;
      max-height: calc(100vh - 10rem);
      text-transform: none;
      background-color: transparentize($color-black, 0.08);
      border-top: 2px solid $color-main;
      opacity: 0;
      visibility: hidden;
      overflow: hidden;
      overflow-y: auto;
      
      .header__category-list.level-2 {
        height: 100%;
        overflow-y: auto;
      }
      
      &:hover, .header__category-name:hover + & {
        height: auto;
        opacity: 1;
        visibility: visible;
      }
    }
    
    &.level-3 {
      position: static;
      background: none;
      width: auto;
      height: auto;
    }
    
    &.is-large, &--brand.level-2 {
      position: fixed;
      top: 6.75rem;
      left: 50%;
      width: 71rem;
      max-height: calc(100vh - 10rem);
      margin-left: -35.5rem;
    }
    
    &.is-large {
      .header__category-list.level-2 {
        padding: 1.25rem 0.5rem 0;
      }
      
      .header__category-item.level-2 {
        width: 16.666%;
      }
      
      .header__category-link.level-2 {
        color: $color-main;
        
        &:hover {
          color: #fff;
        }
      }
      
      .header__category-link.level-3 {
        color: #fff;
        
        &:hover {
          color: $color-main;
        }
      }
    }
    
    &--brand .header__category-item.level-2 {
      display: inline-block;
      vertical-align: top;
      width: 25%;
      padding: 0 1rem;
    }
  }
  
  .header__category-list {
    height: auto;
    overflow: visible;
    
    &.level-1 {
      display: flex;
    }
    
    &.level-2 {
      padding: 0.75rem;
    }
  }
  
  .header__category-item {
    position: relative;
    
    &:not(.level-1):first-child {
      margin-top: 0;
    }
    
    &:not(.level-1):last-child {
      margin-bottom: 0;
    }
  }
  
  .header__category-name {
    padding: 0;
    
    &:hover, &.is-active {
      background: none;
    }
  }
  
  .header__category-item.level-1 {
    margin-right: 0.75rem;
    
    &:last-child {
      margin-rigth: 0;
    }
  }
  
  .header__category-link {
    padding: 0.25rem 0;
    line-height: 1.2;
    transition: $base-transition;
    
    &.level-1 {
      padding: 1rem 0.25rem;
      font-size: 0.875em;
      line-height: 1.5rem;
      color: rgba(255,255,255,0.8);
      
      &:hover, &.is-active {
        color: #fff;
      }
    }
    
    &.level-2, &.level-3 {
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      font-size: 0.8125em;
      color: #fff;
    }
    
    &:hover {
      color: $color-main;
    }
    
    &--furniture.level-1 {
      position: relative;
      min-width: 7.75rem;
      padding-left: 1.125rem;
      padding-right: 1.125rem;
      color: $color-main;
      text-align: center;
      white-space: nowrap;
      background-color: rgba(0,0,0,0.1); 
      
      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        display: block;
        height: 2px;
        background-color: $color-main;
        transition: $base-transition;
      }
      
      &:hover {
        color: #bc9a5e;
        font-weight: 700;
        
        &::after {
          background-color: rgba(0,0,0,0);
        }
      }
      
      .icon {
        vertical-align: top;
        margin-right: 0.25rem;
        padding: 3px;
      }
    }
  }
  
}