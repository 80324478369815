/*Раздел публикации*/

@import "base-vars";

.post-list__image {
  margin-bottom: 1rem;
}
  
.post-list__date {
  left: 0;
  bottom: 0;
  padding: 0.5rem 1rem;
  color: #fff;
  line-height: 1;
  background-color: $color-main;
}

.page-post {
  padding-bottom: 1.5rem;
  border-bottom: 1px solid #e7e7e7;
  display: flex;
  flex-direction: column;
  
  & > * {
    order: 1;
  }
  
  &__title {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    line-height: 1.25;
    background-color: $color-black;
  }
  
  &__image {
    padding: 0;
    
    .page-post & {
      order: 0;
    }
  }
  
  &__info {
    margin-top: 1.5rem;
  }
}

.related-post {
  margin-top: 2.5rem;
  
  &__title {
    margin-bottom: 1rem;
    font-size: 1.5em;
  }
}

@media (max-width: 1023px) {
  
  .post-list__image {
    padding: 0;
  }

  .page-post__share {
    margin-left: -1rem;
    margin-right: -1rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .related-post .post-list__image {
    margin-left: 1rem;
    margin-right: 1rem;
  }
  
}

@media (min-width: 1024px) {
  
  .post-list__image {
    margin-bottom: 2rem;
  }

  .post-list__title {
    color: #333;
  }

  .post-list__date {
    top: 100%;
    right: 0;
    bottom: auto;
    margin-top: 0.75rem;
    color: #999999;
    text-align: center;
    background: none;
  }

  .post-list__annotation {
    margin-bottom: 1rem;
    max-height: 7em;
    font-size: 0.8125em;
    font-weight: 400;
    line-height: 1.75;
    overflow: hidden;
  }

  .page-post {
    display: block;
    padding-bottom: 0;
    border-bottom: 0;
    
    &__title {
      padding-top: 2rem;
      padding-bottom: 2rem;
      line-height: 1;
      background: none;
    } 
    
    &__image {
      padding-left: 2.25rem;
      float: right;
    }
    
    &__info {
      margin-top: 0;
    }
  }

  .related-post {
    margin-top: 4rem;
    
    &__title {
      margin-bottom: 2rem;
      font-size: 2em;
    }
  }

}

@media (min-width: 1280px) {
  
  .post-list__info {
    padding-left: 0.5rem;
    padding-right: 2rem;
  }

  .post-list__annotation {
    margin-top: 0.5rem;
    margin-bottom: 1.5rem;
  }

  .page-post__image {
    padding-left: 1rem;
  }
  
}