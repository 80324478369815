/*Кнопки*/

.button,
.checkout_form input[type="submit"] {
  cursor: pointer;
  display: inline-block;
  vertical-align: top;
  min-width: 10rem;
  max-width: 100%;
  height: 2.5rem;
  padding: 0.75rem 1rem 0.5rem;
  font: 400 1rem/1.25rem $font-serif;
  text-transform: uppercase;
  text-align: center;
  color: #fff;
  white-space: nowrap;
  background-color: $color-main;
  user-select: none;
  transition: all 0.3s;
  
  &:hover, &.is-active {
    background-color: $color-main-dark;
  }
}

.button {
  
  .icon {
    margin-top: -0.25rem;
  }
  
  &--large {
    min-width: 18rem;
    height: 3.25rem;
    padding: 1.125rem 2rem 0.875rem;
    
    @media (min-width: 768px) {
      min-width: 20rem;
    }
  }
  
  &--block {
    display: block;
    width: 100%;
    min-width: 0;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  
  &--dark {
    background-color: $color-main-dark;
    
    &:hover, &.is-active {
      background-color: #6c6353;
    }
  }
  
  &--rounded {
    background-color: rgba(0, 0, 0, 0);
    color: $color-black;
    outline: 1px solid #d0c6b4;
    
    &:hover, &.is-active {
      color: #fff;
      background-color: $color-main;
      outline-color: $color-main;
    }
    
    &.button--large {
      outline-width: 2px;
    }
  }
}